// need to separate custom validators so that they can be included in tests too
import { extend } from 'vee-validate'

extend('indonesiaphonenumber', {
  validate: fieldValue => {
    const parts = /^(08|628|\+628)(\d+)/.exec(fieldValue)

    return (parts && parts[2].length > 7 && parts[2].length < 12) as boolean
  },
})

extend('otpcode', {
  validate: fieldValue => {
    return fieldValue.value.length === 5
  },
})
