import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloLink } from 'apollo-link'
import { isArray, isObject, transform, snakeCase } from 'lodash'
import { Context } from '@nuxt/types'

const snakeize = obj => {
  return transform(obj, (acc: Array<any> | Object, value: any, key: string, target: any) => {
    const snakeKey = isArray(target) ? key : snakeCase(key)
    acc[snakeKey] = isObject(value) ? snakeize(value) : value
  }) as any
}

export default (ctx: Context) => {
  // Since GraphQL field name convention is using camelCase, we need a middleware
  // to convert field name to snake_case so the result that returned to vue
  // component can be rendered seamlessly.
  const middlewareLink = new ApolloLink((operation, forward) => {
    const result = forward(operation)
    return result.map((res: any) => {
      Object.keys(res.data).forEach(key => {
        res.data[key] = snakeize(res.data[key])
      })
      return res
    })
  })

  return {
    httpEndpoint: ctx.$config.apollo.httpEndpoint,
    httpLinkOptions: {
      fetchOptions: {
        mode: 'cors', // GraphQL server is on different host (on subdomain api.)
      },
      // To omit credential cookies being sent to GraphQL server to allow cross origin.
      credentials: 'omit',
    },
    link: middlewareLink,
    cache: new InMemoryCache({
      // To remove `__typename` field in query result, which is used for caching result.
      // Since we have disabled the caching in nuxt.config.ts (apollo.defaultOptions.query.fetchPolicy),
      // we no longer need this and it will reduce query response overhead.
      addTypename: false,
    }),
    tokenName: `${ctx.$config.cookie.prefix}access_token`,
    persisting: false,
    websocketsOnly: false,
    getAuth: () => `Bearer ${ctx.store.state.auth.access_token}`,
  }
}
