/**
 * To store categories like a database on BE
 * so that:
 * - Information is readily available on across pages (even if app fails to fetch the category second time)
 * - No need to perform full fetch when all of the data is available because other page already fetch it for them
 */
import {
  PublicAdminCategory,
  PublicListAdminCategoriesResponse,
} from '@bukalapak/openapigen/products/mitrabangunan/product/v1/public/api'
import {
  PublicAdminCategoryNode,
  PublicListAdminCategoryResponse,
} from '@bukalapak/openapigen/products/mitrabangunan/product/v2/public/api'
import { AxiosResponse } from 'axios'
import { ActionTree, GetterTree, MutationTree } from 'vuex'

export interface AdminCategoryWithSubcategory extends PublicAdminCategory {
  subcategories: PublicAdminCategoryNode[]
}
export interface CategoryVuexState {
  categories: Record<string, PublicAdminCategory>
  categoriesWithSubcategory: Record<string, AdminCategoryWithSubcategory>
  categoriesAndSubcategories: Record<string, PublicAdminCategory>
  total: number | null
}

const initialState: CategoryVuexState = {
  categories: {},
  categoriesWithSubcategory: {},
  categoriesAndSubcategories: {},
  total: null,
}

const mapCategoryAndSubcategoryObject = (
  category: PublicAdminCategory,
  subcategories: PublicAdminCategoryNode[] = []
) => {
  let result: Record<string, PublicAdminCategory> = {}
  for (const subcategory of subcategories) {
    if (subcategory.subcategories && subcategory.subcategories.length > 0) {
      result = {
        ...result,
        ...mapCategoryAndSubcategoryObject(subcategory.category!, subcategory.subcategories),
      }
    } else {
      result = {
        ...result,
        [subcategory.category?.id!]: subcategory.category!,
      }
    }
  }
  return { [category.id!]: { ...category }, ...result }
}

const getters: GetterTree<CategoryVuexState, any> = {
  categoryIds: (state: CategoryVuexState) => Object.keys(state.categories),
  // Rought estimation, since category is not updated too often
  categoriesNotUpdated: (state: CategoryVuexState, getters) => {
    return state.total !== getters.categoryIds.length
  },
}

const mutations: MutationTree<CategoryVuexState> = {
  setItems(state: CategoryVuexState, newItems: PublicAdminCategory[]) {
    const newCategories = {}
    newItems.forEach(item => {
      if (item.id) {
        newCategories[item.id] = item
      }
    })
    state.categories = newCategories
    state.total = newItems.length
  },
  setCategoryWithSubcategoryItems(state: CategoryVuexState, newItems: PublicAdminCategoryNode[]) {
    const newCategories = {}
    let newCategoriesAndSubcategories = {}
    newItems.forEach(item => {
      if (item.category?.id) {
        newCategories[item.category?.id] = {
          ...item.category,
          subcategories: item.subcategories || [],
        }

        newCategoriesAndSubcategories = {
          ...newCategoriesAndSubcategories,
          ...mapCategoryAndSubcategoryObject(item.category, item.subcategories),
        }
      }
    })
    state.categoriesAndSubcategories = newCategoriesAndSubcategories
    state.categoriesWithSubcategory = newCategories
    state.total = newItems.length
  },
}

const actions: ActionTree<CategoryVuexState, any> = {
  async load({ commit }) {
    const result: AxiosResponse<PublicListAdminCategoriesResponse> =
      await this.$api.adminCategoryApi.adminCategoryServiceListAdminCategories()
    commit('setItems', result.data?.categories || [])
    return result
  },

  async loadCategoryListV2({ commit }) {
    const result: AxiosResponse<PublicListAdminCategoryResponse> =
      await this.$api.adminCategoryServiceApiV2.adminCategoryServiceListAdminCategory()
    commit('setCategoryWithSubcategoryItems', result.data.categories || [])
  },
}

export default {
  namespaced: true,
  state: () => ({ ...initialState }),
  mutations,
  getters,
  actions,
}
