import axios, { AxiosResponse } from 'axios'

import { APIResponse } from '../../../types/api/api-response'
import { CMSBasePath } from '../../../types/api/cms'
import { Article, ArticleCategory, ArticleDetail } from '../../../types/model/articles'
import { SitemapCount } from '~/types/model/sitemap'

export type GetArticleParams = {
  limit: number
  offset: number
  title: string
  category_id: number
  topic: string
  sort: string
}

export const cmsClient = () => {
  const baseHeader = {
    Authorization: `Basic ${process.env.CMS_BUKABANGUNAN_SECRET}`,
  }

  const requestCMSData = <T>(path: string, params = {}) => {
    return axios.get<T>(path, { headers: { ...baseHeader }, params })
  }

  const requestCMSArticleDetail = (id: number): Promise<AxiosResponse<APIResponse<ArticleDetail>>> =>
    requestCMSData<APIResponse<ArticleDetail>>(`${CMSBasePath.articles}/${id}`, { id })

  const requestCMSArticles = (params: Partial<GetArticleParams>): Promise<AxiosResponse<APIResponse<Article[]>>> =>
    requestCMSData<APIResponse<Article[]>>(CMSBasePath.articles, params)

  const requestCMSCategories = (): Promise<AxiosResponse<APIResponse<ArticleCategory[]>>> =>
    requestCMSData<APIResponse<ArticleCategory[]>>(CMSBasePath.categries)

  const requestCMSArticleSitemapCount = (): Promise<AxiosResponse<APIResponse<SitemapCount>>> =>
    requestCMSData<APIResponse<SitemapCount>>(`${CMSBasePath.sitemap}/articles/count`)

  return { requestCMSArticleDetail, requestCMSArticles, requestCMSCategories, requestCMSArticleSitemapCount }
}
