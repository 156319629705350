import type { MutationTree } from 'vuex'
import { SellingListSearchProvider } from '~/types/component/seller-product-list'

export type SellerProductVuexState = {
  filter: SellingListSearchProvider['searchModule']['params']
  tempFilter: SellingListSearchProvider['searchModule']['params']
}

const initialState: SellerProductVuexState = {
  filter: {},
  tempFilter: {},
}

const mutations: MutationTree<SellerProductVuexState> = {
  setState(state, [key, payload]) {
    state[key] = payload
  },
}

export default {
  namespaced: true,
  state: () => ({ ...initialState }),
  mutations,
  getters: {},
  actions: {},
}
