import { Oauth2Scheme } from '@nuxtjs/auth-next/dist/runtime'
import { Plugin } from '@nuxt/types'

import { createOpenapi } from '~/utils/api/openapi-adapter'

const OpenapiPlugin: Plugin = (ctx, inject) => {
  if (process.server) return

  inject(
    'openapi',
    createOpenapi({
      baseURL: ctx.$config?.apiBasePath,
      instance: ctx.$axios,
      getToken: () => (ctx.$auth?.strategy as unknown as Oauth2Scheme).token?.get()?.toString().replace('Bearer ', ''),
    })
  )
}

export default OpenapiPlugin
