import type { Storage } from '@nuxtjs/auth-next/dist/runtime'
import { TokenStatus } from '@nuxtjs/auth-next/dist/runtime'
import type { JwtPayload } from 'jwt-decode'
import jwtDecode from 'jwt-decode'
import type Oauth2HydraScheme from '../oauth2-hydra'
// import { addTokenPrefix } from './index'

export class CustomToken {
  public scheme: Oauth2HydraScheme
  public $storage: Storage

  constructor(scheme: Oauth2HydraScheme, storage: Storage) {
    this.scheme = scheme
    this.$storage = storage
  }

  get(): string | boolean {
    const key = this.scheme.options.token.prefix // removed prefix
    return `${this.scheme.options.token.type} ${this.$storage.getUniversal(key)}`
  }

  _get(): string | boolean {
    const key = this.scheme.options.token.prefix // removed prefix
    return this.$storage.getUniversal(key) as string | boolean
  }

  set(tokenValue: string | boolean): string | boolean {
    const token = tokenValue // addTokenPrefix(tokenValue, this.scheme.options.token.type)

    this._setToken(token)
    this._updateExpiration(token)

    if (typeof token === 'string') {
      this.scheme.requestHandler.setHeader(`${this.scheme.options.token.type} ${token}`)
    }
    return token
  }

  sync(): string | boolean {
    const token = this._syncToken()
    this._syncExpiration()

    if (typeof token === 'string') {
      this.scheme.requestHandler.setHeader(`${this.scheme.options.token.type} ${token}`)
    }

    return token
  }

  reset(): void {
    this.scheme.requestHandler.clearHeader()
    this._setToken(false)
    this._setExpiration(false)
  }

  status(): TokenStatus {
    return new TokenStatus(this._get(), this._getExpiration())
  }

  private _getExpiration(): number | false {
    const key = this.scheme.options.token.expirationPrefix // removed prefix
    return this.$storage.getUniversal(key) as number | false
  }

  private _setExpiration(expiration: number | false): number | false {
    if (process.client) {
      return expiration
    }
    const key = this.scheme.options.token.expirationPrefix // removed prefix
    return this.$storage.setUniversal(key, expiration) as number | false
  }

  private _syncExpiration(): number | false {
    if (process.client) {
      return this._getExpiration()
    }
    const key = this.scheme.options.token.expirationPrefix // removed prefix
    return this.$storage.syncUniversal(key) as number | false
  }

  private _updateExpiration(token: string | boolean): number | false | void {
    let tokenExpiration
    const issuedAt = Date.now()
    const ttl = Number(this.scheme.options.token.maxAge) * 1000
    const expiresAt = ttl ? issuedAt + ttl : 0
    try {
      tokenExpiration = (jwtDecode<JwtPayload>(`${token}`)?.exp ?? 0) * 1000 || expiresAt
    } catch (error) {
      // If the token is not jwt, we can't decode and refresh it, use _tokenExpiresAt value
      tokenExpiration = expiresAt
      if (!(error && error.name === 'InvalidTokenError')) {
        throw error
      }
    }

    // Set token expiration
    return this._setExpiration(tokenExpiration || false)
  }

  private _setToken(token: string | boolean): string | boolean {
    if (process.client) {
      // TODO: send save token on client
      return token
    }
    const key = this.scheme.options.token.prefix // removed prefix
    return this.$storage.setUniversal(key, token) as string | boolean
  }

  private _syncToken(): string | boolean {
    if (process.client) {
      return this._get() // prevent writing cookie
    }
    const key = this.scheme.options.token.prefix // removed prefix
    return this.$storage.syncUniversal(key) as string | boolean
  }
}
