import {
  AdminAnnouncementServiceApi,
  AdminBannerImageServiceApi,
  AdminBannerServiceApi,
  AdminBrandServiceApi,
  AdminBulkUpsertMasterProductServiceApi,
  AdminBulkUpsertSellingProductServiceApi,
  AdminBulkUpsertTariffServiceApi,
  AdminCategoryImageServiceApi,
  AdminCategoryServiceApi,
  AdminMasterProductImageServiceApi,
  AdminMasterProductServiceApi,
  AdminProducerServiceApi,
  AdminSellingProductServiceApi,
  AdminTariffServiceApi,
  AdminWarehouseServiceApi,
  CategoryServiceApi,
  Configuration,
  ConfigurationParameters,
  MasterProductServiceApi,
  SellerWarehouseServiceApi,
  SellingProductServiceApi,
  WarehouseServiceApi,
} from '@bukalapak/openapigen/products/mitrabangunan/product/v1/public'
import {
  AdminB2BOrderServiceApi,
  AdminBnplServiceApi,
  AdminBnplUserCsvServiceApi,
  AdminOrderHistoryServiceApi,
  AdminOrderServiceApi,
  AdminPaylaterLogCsvServiceApi,
  AdminPaylaterLogServiceApi,
  AdminPaylaterTransactionServiceApi,
  AdminPaylaterUserServiceApi,
  SellerOrderServiceApi,
} from '@bukalapak/openapigen/products/mitrabangunan/transaction/v1/public'
import { AdminUserServiceApi, UserServiceApi } from '@bukalapak/openapigen/products/mitrabangunan/user/v1/public'
import { OTPServiceApi } from '@bukalapak/openapigen/products/reusable/authentication/v1/public'
import { OnsiteNotificationServiceApi } from '@bukalapak/openapigen/products/reusable/communication/v1/public'
import { AddressServiceApi } from '@bukalapak/openapigen/products/reusable/logistic/v1/public/api'
import {
  AccountAdminServiceApi,
  AccountLogAdminServiceApi,
  AccountServiceApi,
  AccountTypeServiceApi,
  BankConfigServiceApi,
  BillingServiceApi,
  MutationAdminServiceApi,
  MutationServiceApi,
  PaylaterUserManagementApi,
  WithdrawalAdminServiceApi,
  WithdrawalBankAccountAdminServiceApi,
  WithdrawalBankAccountLogAdminServiceApi,
  WithdrawalBankAccountServiceApi,
  WithdrawalLogAdminServiceApi,
  WithdrawalServiceApi,
} from '@bukalapak/openapigen/products/reusable/payment/v1/public'
import {
  AdminUploadFileApi,
  AuditTrailServiceApi,
  CampaignsServiceApi,
  FileServiceApi,
  VoucherkuServiceApi,
} from '@bukalapak/openapigen/products/reusable/subsidy/v1/public/api'

// region import for v2 services
import { AdminCategoryServiceApi as AdminCategoryServiceApiV2 } from '@bukalapak/openapigen/products/mitrabangunan/product/v2/public/'
// endregion

import { Plugin } from '@nuxt/types'
import { Oauth2Scheme } from '@nuxtjs/auth-next/dist/runtime'
import jsCookie from 'js-cookie'
import { acessTokenAppKey } from '../constants/token'

export const setupAPIClient = (accessToken: string, basePath: string) => {
  // TODO: implement auth?
  const configAPI: ConfigurationParameters = {
    basePath,
    accessToken,
  }

  return {
    otpServiceApi: new OTPServiceApi(
      new Configuration({
        ...configAPI,
        baseOptions: {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      })
    ),
    accountTypesApi: new AccountTypeServiceApi(new Configuration(configAPI)),
    categoryAPI: new CategoryServiceApi(new Configuration(configAPI)),
    addressAPI: new AddressServiceApi(new Configuration(configAPI)),
    adminUserApi: new AdminUserServiceApi(new Configuration(configAPI)),
    adminOrderApi: new AdminOrderServiceApi(new Configuration(configAPI)),
    adminOrderHistoryApi: new AdminOrderHistoryServiceApi(new Configuration(configAPI)),
    adminWarehouseApi: new AdminWarehouseServiceApi(new Configuration(configAPI)),
    adminCategoryApi: new AdminCategoryServiceApi(new Configuration(configAPI)),
    adminMasterProductAPI: new AdminMasterProductServiceApi(new Configuration(configAPI)),
    adminMasterProductImageApi: new AdminMasterProductImageServiceApi(new Configuration(configAPI)),
    adminSellingProductAPI: new AdminSellingProductServiceApi(new Configuration(configAPI)),
    adminBulkNewMasterProductApi: new AdminBulkUpsertMasterProductServiceApi(new Configuration(configAPI)),
    adminProducerAPI: new AdminProducerServiceApi(new Configuration(configAPI)),
    adminTariffAPI: new AdminTariffServiceApi(new Configuration(configAPI)),
    adminPaylaterLogCSVAPI: new AdminPaylaterLogCsvServiceApi(new Configuration(configAPI)),
    adminPaylaterLogAPI: new AdminPaylaterLogServiceApi(new Configuration(configAPI)),
    warehouseApi: new WarehouseServiceApi(new Configuration(configAPI)),
    adminBrandServiceApi: new AdminBrandServiceApi(new Configuration(configAPI)),
    sellingProductApi: new SellingProductServiceApi(new Configuration(configAPI)),
    masterProductApi: new MasterProductServiceApi(new Configuration(configAPI)),
    adminCategoryImageServiceApi: new AdminCategoryImageServiceApi(new Configuration(configAPI)),
    adminCategoryServiceApi: new AdminCategoryServiceApi(new Configuration(configAPI)),
    adminCategoryServiceApiV2: new AdminCategoryServiceApiV2(new Configuration(configAPI)),
    userServiceApi: new UserServiceApi(new Configuration(configAPI)),
    adminPaylaterLogCsvApi: new AdminPaylaterLogCsvServiceApi(new Configuration(configAPI)),
    adminPaylaterLogApi: new AdminPaylaterLogServiceApi(new Configuration(configAPI)),
    adminPaylaterTransactionApi: new AdminPaylaterTransactionServiceApi(new Configuration(configAPI)),
    adminBulkUpsertTariffServiceApi: new AdminBulkUpsertTariffServiceApi(new Configuration(configAPI)),
    sellerWarehouseServiceApi: new SellerWarehouseServiceApi(new Configuration(configAPI)),
    adminBannerImageApi: new AdminBannerImageServiceApi(new Configuration(configAPI)),
    adminBannerApi: new AdminBannerServiceApi(new Configuration(configAPI)),
    adminB2BOrderApi: new AdminB2BOrderServiceApi(new Configuration(configAPI)),
    adminAnnouncementApi: new AdminAnnouncementServiceApi(new Configuration(configAPI)),
    adminPaylaterUserApi: new AdminPaylaterUserServiceApi(new Configuration(configAPI)),
    adminBnplServiceApi: new AdminBnplServiceApi(new Configuration(configAPI)),
    adminBnplUserCsvServiceApi: new AdminBnplUserCsvServiceApi(new Configuration(configAPI)),

    // Subsidy related API
    campaignsApi: new CampaignsServiceApi(new Configuration(configAPI)),
    subsidiesAuditTrailApi: new AuditTrailServiceApi(new Configuration(configAPI)),
    fileSubsidyApi: new FileServiceApi(new Configuration(configAPI)),
    subsidyAttributeUpload: new AdminUploadFileApi(new Configuration(configAPI)),
    voucherkuServiceApi: new VoucherkuServiceApi(new Configuration(configAPI)),

    // Seller related API
    sellerOrderServiceApi: new SellerOrderServiceApi(new Configuration(configAPI)),
    onsiteNotificationServiceApi: new OnsiteNotificationServiceApi(new Configuration(configAPI)),

    // Wallet related API
    walletAccountApi: new AccountServiceApi(new Configuration(configAPI)),
    adminMutationApi: new MutationAdminServiceApi(new Configuration(configAPI)),
    adminPaymentAccountApi: new AccountAdminServiceApi(new Configuration(configAPI)),
    adminPaymentAccountLogApi: new AccountLogAdminServiceApi(new Configuration(configAPI)),
    bankConfigAPI: new BankConfigServiceApi(new Configuration(configAPI)),
    withdrawalAPI: new WithdrawalServiceApi(new Configuration(configAPI)),
    withdrawalAdminAPI: new WithdrawalAdminServiceApi(new Configuration(configAPI)),
    withdrawalLogAdminAPI: new WithdrawalLogAdminServiceApi(new Configuration(configAPI)),
    withdrawalBankAccountAPI: new WithdrawalBankAccountServiceApi(new Configuration(configAPI)),
    withdrawalBankAccountAdminApi: new WithdrawalBankAccountAdminServiceApi(new Configuration(configAPI)),
    withdrawalBankAccountLogAdminApi: new WithdrawalBankAccountLogAdminServiceApi(new Configuration(configAPI)),
    mutationServiceApi: new MutationServiceApi(new Configuration(configAPI)),
    billingAPi: new BillingServiceApi(new Configuration(configAPI)),
    paylaterUserManagementApi: new PaylaterUserManagementApi(new Configuration(configAPI)),

    adminBulkUpsertSellingProductServiceApi: new AdminBulkUpsertSellingProductServiceApi(new Configuration(configAPI)),
  }
}
export type ApiModules = ReturnType<typeof setupAPIClient>

const apiPlugin: Plugin = ({ $auth, $config }, inject) => {
  if (process.server) {
    return
  }

  // when the access token from app available then the token would be used for each api request,
  // this is needed to handle logged in case in the buyer app webview.
  const accessTokenApp = jsCookie.get(acessTokenAppKey)
  // eslint-disable-next-line dot-notation
  const modules = setupAPIClient(
    accessTokenApp || ($auth.strategy as unknown as Oauth2Scheme).token.get().toString().replace('Bearer ', ''),
    $config.apiBasePath
  )
  inject('api', modules)
}

export default apiPlugin
