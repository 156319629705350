export const availableStrategies = ['admin', 'buyer', 'seller']

export const whitelistRouteName = [
  'buyer-faq',
  'buyer-faq-slug',
  'buyer-wallet',
  'buyer-withdrawal',
  'buyer-withdrawal-history',
  'buyer-withdrawal-bank-add',
  'buyer-withdrawal-bank-empty',
  'buyer-withdrawal-id',
  'buyer-withdrawal-bank-bank_id-edit',
  'seller-reset-password-confirm-phone',
  'seller-reset-password-new-password',
  'seller-reset-password-verify-otp',
]
