import { ActionTree, MutationTree } from 'vuex'
import WalletAPI from '~/api/wallet'
import { APIResponse } from '~/types/api/api-response'
import { BalanceMutation } from '~/types/data/wallet/balance-mutation'

export type WalletVuexState = {
  balanceMutation: BalanceMutation[]
}

const initialState: WalletVuexState = {
  balanceMutation: [],
}

const mutations: MutationTree<WalletVuexState> = {
  setBalanceMutation(state, value: BalanceMutation[]) {
    state.balanceMutation = value
  },
}

const actions: ActionTree<WalletVuexState, any> = {
  async fetchWalletData({ dispatch }) {
    try {
      await dispatch('fetchBalanceMutation')
    } catch (error) {
      throw new Error(error)
    }
  },
  async fetchBalanceMutation({ commit }) {
    const { data }: APIResponse<BalanceMutation[]> = await WalletAPI.getBalanceMutation()
    commit('setBalanceMutation', data)
  },
}

export default {
  namespaced: true,
  state: () => ({ ...initialState }),
  mutations,
  getters: {},
  actions,
}
