import {
  SharedCampaign,
  SharedFile,
  SharedSubsidyReward,
} from '@bukalapak/openapigen/products/reusable/subsidy/v1/public/api'
import type { GetterTree, MutationTree } from 'vuex'
import { RelationalOperator } from '~/constants/pages/admin/campaign/rule-group-mapping'
import { GroupRule, UserTargetedRule } from '~/types/data/campaign/rule'

export type CampaignVuexState = {
  step1: { campaign: object }
  step2: { rewards: object }
  step3: { groups: GroupRule[]; userTargeted: UserTargetedRule | undefined }
  currentCampaign: SharedCampaign | undefined
  selectedBanner: SharedFile
}

const initialState: CampaignVuexState = {
  step1: {
    campaign: {},
  },
  step2: {
    rewards: {},
  },
  step3: {
    groups: [],
    userTargeted: undefined,
  },
  currentCampaign: undefined,
  selectedBanner: {},
}

const getters: GetterTree<CampaignVuexState, any> = {
  currentReward: (state: CampaignVuexState) => {
    return state.currentCampaign?.subsidy?.rewards ? state.currentCampaign?.subsidy?.rewards[0] : {}
  },
  normalizeCurrentRuleGroup: (_state, getters) => {
    const reward = getters.currentReward as SharedSubsidyReward
    if (!reward?.rule_group) return null

    const group = reward?.rule_group?.groups ? JSON.parse(reward?.rule_group?.groups) : {}

    if (!group.rules) return group

    const rulesWithAdjustedOperator = group.rules?.map(rule => {
      let operator = rule.operator

      if (typeof rule.operator === 'number') {
        operator = RelationalOperator[rule.operator]
      }

      return {
        ...rule,
        operator,
      }
    })

    return {
      ...group,
      rules: rulesWithAdjustedOperator,
    }
  },
  getCurrentRuleByName: (state: CampaignVuexState) => (ruleName: string) => {
    return state.currentCampaign?.subsidy?.rules?.find(rule => rule.name === ruleName)
  },
}

const mutations: MutationTree<CampaignVuexState> = {
  resetStepStates(state) {
    state.step1.campaign = {}
    state.step2.rewards = {}
    state.step3.groups = []
  },
  setStepState(state, [step, key, payload]) {
    state[step][key] = payload
  },
  setCurrentCampaign(state, campaign: SharedCampaign) {
    state.currentCampaign = campaign
  },
  setSelectedBanner(state, payload) {
    state.selectedBanner = payload
  },
}

export default {
  namespaced: true,
  state: () => ({ ...initialState }),
  getters,
  mutations,
}
