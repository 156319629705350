import type { Storage } from '@nuxtjs/auth-next/dist/runtime'
import { TokenStatus } from '@nuxtjs/auth-next/dist/runtime'
import type { JwtPayload } from 'jwt-decode'
import jwtDecode from 'jwt-decode'
import type Oauth2HydraScheme from '../oauth2-hydra'
// import { addTokenPrefix } from './index'

export class CustomRefreshToken {
  public scheme: Oauth2HydraScheme
  public $storage: Storage

  constructor(scheme: Oauth2HydraScheme, storage: Storage) {
    this.scheme = scheme
    this.$storage = storage
  }

  get(): string | boolean {
    const key = this.scheme.options.refreshToken.prefix // removed prefix
    return this.$storage.getUniversal(key) as string | boolean
  }

  set(tokenValue: string | boolean): string | boolean {
    const refreshToken = tokenValue // addTokenPrefix(tokenValue, this.scheme.options.refreshToken.type)

    this._setToken(refreshToken)
    this._updateExpiration(refreshToken)

    return refreshToken
  }

  sync(): string | boolean {
    const refreshToken = this._syncToken()
    this._syncExpiration()

    return refreshToken
  }

  reset(): void {
    this._setToken(false)
    this._setExpiration(false)
  }

  status(): TokenStatus {
    return new TokenStatus(this.get(), this._getExpiration())
  }

  private _getExpiration(): number | false {
    const key = this.scheme.options.refreshToken.expirationPrefix // removed prefix
    return this.$storage.getUniversal(key) as number | false
  }

  private _setExpiration(expiration: number | false): number | false {
    const key = this.scheme.options.refreshToken.expirationPrefix // removed prefix
    return this.$storage.setUniversal(key, expiration) as number | false
  }

  private _syncExpiration(): number | false {
    const key = this.scheme.options.refreshToken.expirationPrefix // removed prefix
    return this.$storage.syncUniversal(key) as number | false
  }

  private _updateExpiration(refreshToken: string | boolean): number | false | void {
    let refreshTokenExpiration
    const tokenProp = {
      issuedAt: Date.now(),
      ttl: Number(this.scheme.options.refreshToken.maxAge) * 1000,
    }
    const expiresAt = tokenProp.ttl ? tokenProp.issuedAt + tokenProp.ttl : 0

    try {
      refreshTokenExpiration = (jwtDecode<JwtPayload>(refreshToken + '')?.exp ?? 0) * 1000 || expiresAt
    } catch (error) {
      // If the token is not jwt, we can't decode and refresh it, use _tokenExpiresAt value
      refreshTokenExpiration = expiresAt

      if (!((error && error.name === 'InvalidTokenError') /* jwtDecode */)) {
        throw error
      }
    }

    // Set token expiration
    return this._setExpiration(refreshTokenExpiration || false)
  }

  private _setToken(refreshToken: string | boolean): string | boolean {
    const key = this.scheme.options.refreshToken.prefix // removed prefix
    return this.$storage.setUniversal(key, refreshToken) as string | boolean
  }

  private _syncToken(): string | boolean {
    const key = this.scheme.options.refreshToken.prefix // removed prefix
    return this.$storage.syncUniversal(key) as string | boolean
  }
}
