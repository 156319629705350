import Middleware from './middleware'
import { Auth, authMiddleware, ExpiredAuthSessionError } from '~auth/runtime'

// Active schemes
import { default as Scheme$5ff3 } from '/builds/buka20/o2o-new-vertical/mitra_bangunan_fe/config/auth-schemes/oauth2-hydra.ts'

Middleware.auth = authMiddleware

export default function (ctx, inject) {
  // Options
  const options = {
  "resetOnError": false,
  "ignoreExceptions": false,
  "scopeKey": "scope",
  "rewriteRedirects": true,
  "fullPathRedirect": false,
  "watchLoggedIn": true,
  "redirect": {
    "login": "/login_trigger",
    "logout": "/",
    "home": "/home",
    "callback": "/oauth_callback"
  },
  "vuex": {
    "namespace": "auth"
  },
  "cookie": {
    "prefix": "auth.",
    "options": {
      "path": "/",
      "domain": "localhost",
      "secure": true,
      "httpOnly": true
    }
  },
  "localStorage": false,
  "defaultStrategy": "nvAdmin"
}

  // Create a new Auth instance
  const $auth = new Auth(ctx, options)

  // Register strategies
  // nvAdmin
  $auth.registerStrategy('nvAdmin', new Scheme$5ff3($auth, {
  "name": "nvAdmin",
  "endpoints": {},
  "token": {
    "property": "access_token",
    "type": "Bearer",
    "maxAge": 1800
  },
  "refreshToken": {
    "property": "refresh_token",
    "maxAge": 2592000
  },
  "responseType": "code",
  "grantType": "authorization_code",
  "accessType": "offline",
  "scope": [
    "offline",
    "*.*.*",
    "admin"
  ],
  "state": "UNIQUE_AND_NON_GUESSABLE",
  "codeChallengeMethod": "implicit",
  "acrValues": ""
}))

  // nvBuyer
  $auth.registerStrategy('nvBuyer', new Scheme$5ff3($auth, {
  "name": "nvBuyer",
  "endpoints": {},
  "token": {
    "property": "access_token",
    "type": "Bearer",
    "maxAge": 1800
  },
  "refreshToken": {
    "property": "refresh_token",
    "maxAge": 2592000
  },
  "responseType": "code",
  "grantType": "authorization_code",
  "accessType": "offline",
  "redirectUri": "com.bukabangunan.app:/oauthredirect",
  "scope": [
    "offline",
    "*.*.*"
  ],
  "state": "UNIQUE_AND_NON_GUESSABLE",
  "codeChallengeMethod": "S256",
  "acrValues": "",
  "clientId": ""
}))

  // nvSeller
  $auth.registerStrategy('nvSeller', new Scheme$5ff3($auth, {
  "name": "nvSeller",
  "endpoints": {},
  "token": {
    "property": "access_token",
    "type": "Bearer",
    "maxAge": 1800
  },
  "refreshToken": {
    "property": "refresh_token",
    "maxAge": 2592000
  },
  "responseType": "code",
  "grantType": "authorization_code",
  "accessType": "offline",
  "scope": [
    "offline",
    "*.*.*"
  ],
  "state": "UNIQUE_AND_NON_GUESSABLE",
  "codeChallengeMethod": "implicit",
  "acrValues": ""
}))

  // Inject it to nuxt context as $auth
  inject('auth', $auth)
  ctx.$auth = $auth

  // Initialize auth
  return $auth.init().catch(error => {
    if (process.client) {
      // Don't console log expired auth session errors. This error is common, and expected to happen.
      // The error happens whenever the user does an ssr request (reload/initial navigation) with an expired refresh
      // token. We don't want to log this as an error.
      if (error instanceof ExpiredAuthSessionError) {
        return
      }

      console.error('[ERROR] [AUTH]', error)
    }
  })
}
