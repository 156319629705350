





















import { BlButtonAv, BlTextAv } from '@bukalapak/bazaar-mweb'
import BlIllustrationAv from '@bukalapak/bazaar-visual/dist/components/BlIllustrationAv'
import type { NuxtError } from '@nuxt/types'
import Vue, { PropType } from 'vue'

export default Vue.extend({
  components: {
    BlIllustrationAv,
    BlTextAv,
    BlButtonAv,
  },
  props: {
    error: {
      required: true,
      type: Object as PropType<NuxtError>,
    },
  },
  data() {
    return {
      baseProp: {
        404: {
          title: 'Halaman tidak ditemukan',
          description: 'Periksa kembali link yang ingin kamu tuju.',
          cta: 'Kembali',
          image: 'img_medium_search_question',
          actions: () => {
            window.history.go(-1)
          },
        },
        '500|501|502|503|504': {
          title: 'Terjadi kesalahan',
          description: 'Tidak perlu khawatir, tim kami akan segera memperbaiki ini.',
          cta: 'Kembali ke Beranda',
          image: 'img_medium_fun_sorry',
          actions: () => {
            this.$router.replace('/')
          },
        },
        default: {
          title: 'Terjadi kesalahan',
          description: '',
          cta: 'Muat Ulang',
          image: '',
          actions: () => {
            window.location.reload()
          },
        },
      },
    }
  },
  computed: {
    selectedError(): {
      title: string
      description: string
      cta: string
      image: string
      actions: Function
      details: string
    } {
      const entry = Object.keys(this.baseProp).find(key => key.includes(`${this.error.statusCode ?? 404}`)) ?? 'default'
      return { ...this.baseProp[entry], details: this.error.message }
    },
  },
  mounted() {
    this.$sentry.setUser(this.$auth.user)
    this.$sentry.captureException(this.error)
  },
})
