import { SharedRuleOperatorEnum } from '@bukalapak/openapigen/products/reusable/subsidy/v1/public/api'

export const provisionTypes = {
  AND: 'Seluruh rules harus terpenuhi (AND)',
  OR: 'Cukup salah satu rule terpenuhi (OR)',
  XOR: 'Rules yang terpenuhi tidak boleh lebih dari 1 (XOR)',
}

export enum RuleField {
  PaymentMethod = 'Payment.Billings.PaymentMethod',
  Brand = 'Orders.Products.BrandID',
  CategoryID = 'Orders.Products.CategoryID',
  Warehouse = 'Orders.Products.WarehouseID',
  BuyerCategoryID = 'Buyer.BuyerCategoryID',
}

export const ruleFields = {
  [RuleField.PaymentMethod]: 'Payment Type',
  [RuleField.Brand]: 'Brand',
  [RuleField.CategoryID]: 'Category',
  [RuleField.Warehouse]: 'Warehouse/Gudang',
  [RuleField.BuyerCategoryID]: 'Buyer Category',
}

export const disabledRuleFields = {
  [RuleField.PaymentMethod]: false,
  [RuleField.Brand]: false,
  [RuleField.CategoryID]: false,
  [RuleField.Warehouse]: false,
  [RuleField.BuyerCategoryID]: false,
}

export enum PaymentMethod {
  POD = 'pod',
  TOP = 'top',
  PREPAID = 'prepaid',
  MOFA = 'mofa',
}

export enum BusinessType {
  Distributor = 1,
  BuildingMaterialShop = 2,
  Contractor = 3,
  ElectricShop = 4,
  PaintShop = 5,
  HardwareStore = 6,
  Individual = 7,
  Other = 8,
}

export const paymentMethods = {
  [PaymentMethod.POD]: 'Postpaid - VA BCA',
  [PaymentMethod.TOP]: 'Postpaid - TOP/Paylater',
  [PaymentMethod.PREPAID]: 'Prepaid - VA BCA',
  [PaymentMethod.MOFA]: 'Postpaid - Mofa',
}

export const buyerCategories = {
  [BusinessType.Distributor]: 'Distributor',
  [BusinessType.BuildingMaterialShop]: 'Toko Bahan Bangunan',
  [BusinessType.Contractor]: 'Kontraktor',
  [BusinessType.ElectricShop]: 'Toko Listrik',
  [BusinessType.PaintShop]: 'Toko Cat',
  [BusinessType.HardwareStore]: 'Toko Perkakas',
  [BusinessType.Individual]: 'Perseorangan',
  [BusinessType.Other]: 'Lainnya',
}

export enum RelationalOperator {
  RELATIONAL_OPERATOR_UNSPECIFIED = 0, // unspecified enum for relational operator
  RELATIONAL_OPERATOR_EQ = 1, // Equal or `==`
  RELATIONAL_OPERATOR_LT = 2, // Less than or `<`
  RELATIONAL_OPERATOR_LTEQ = 3, // Less than equal or `<=`
  RELATIONAL_OPERATOR_GT = 4, // Greater than or `>`
  RELATIONAL_OPERATOR_GTEQ = 5, // Greater than equal or `>=`
  RELATIONAL_OPERATOR_IOF = 6, // Is one of
  RELATIONAL_OPERATOR_INOF = 7, // Is not one of
}

export const operatorTypes: Record<SharedRuleOperatorEnum, string> = {
  [SharedRuleOperatorEnum.Unspecified]: 'Unspecified',
  [SharedRuleOperatorEnum.Eq]: 'Equal',
  [SharedRuleOperatorEnum.Lt]: 'Less than',
  [SharedRuleOperatorEnum.Lteq]: 'Less than equal',
  [SharedRuleOperatorEnum.Gt]: 'Greater than',
  [SharedRuleOperatorEnum.Gteq]: 'Greater than equal',
  [SharedRuleOperatorEnum.Iof]: 'Is one of',
  [SharedRuleOperatorEnum.Inof]: 'Is not one of',
}
