import cookie from 'js-cookie'
import { detectBreakpoint, useViewport } from '~viewport'

/**
 * @type {import('@nuxt/types').Plugin}
 */
export default async function ({ nuxtState }, inject) {
  const options = {"breakpoints":{"mobile":1,"tablet":680,"desktop":1024,"desktopWide":1440},"cookieName":"bukabangunan_viewport","defaultBreakpoints":{"desktop":"desktop","mobile":"mobile","tablet":"tablet"},"fallbackBreakpoint":"desktop"}
  let breakpoint

  if (nuxtState.viewport) {
    breakpoint = nuxtState.viewport
  } else {
    const viewportCookie = cookie.get(options.cookieName)
    breakpoint = await detectBreakpoint.call(options, viewportCookie, navigator.userAgent)
  }

  const viewport = useViewport(options, breakpoint)
  inject('viewport', viewport)
}
