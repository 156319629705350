import { Plugin } from '@nuxt/types'
import { Request, Response } from 'express'
import jsCookie from 'js-cookie'

declare module 'vue/types/vue' {
  interface Vue {
    $csrfToken: string
  }
}

const plugin: Plugin = (ctx, inject) => {
  const cookieName = `${ctx.$config.cookie.prefix}csrf`

  if (process.server) {
    const req = ctx.req as Request
    const res = ctx.res as Response

    const csrfToken = req.csrfToken()
    res.cookie(cookieName, csrfToken)

    inject('csrfToken', csrfToken)
    return
  }

  inject('csrfToken', jsCookie.get(cookieName))
}

export default plugin
