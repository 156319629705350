import { Context, Middleware } from '@nuxt/types'
import { startCase } from 'lodash'
import { availableStrategies, whitelistRouteName } from '~/constants/auth'

const NamespaceMiddleware: Middleware = context => {
  const paths = context.route.path.slice(1).split('/')
  const isRouteWhitelisted = whitelistRouteName.includes(`${context.route.name}`)

  const [namespace] = paths

  if (isRouteWhitelisted || !availableStrategies.includes(namespace) || !context.$auth.loginWith) return

  // eslint-disable-next-line dot-notation
  if (process.server) context.res['locals'].namespace = namespace
  context.namespace = namespace as Context['namespace']

  // Introspect user type, if not admin, redir to home checker
  const strategy = context.$auth.strategy.name ?? ''
  if (strategy !== `nv${startCase(namespace)}`) {
    context.redirect(`/${strategy.toLowerCase().replace(/^nv/, '')}`)
  }
}

export default NamespaceMiddleware
