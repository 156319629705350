import type { MutationTree } from 'vuex'

export type LayoutVuexState = {
  collapsedMenus: { [key: string]: boolean }
}

const initialState: LayoutVuexState = {
  collapsedMenus: {},
}

const mutations: MutationTree<LayoutVuexState> = {
  toggleCollapsedMenu(state, payload) {
    state.collapsedMenus = {
      ...state.collapsedMenus,
      [payload]: !state.collapsedMenus[payload],
    }
  },
}

export default {
  namespaced: true,
  state: () => ({ ...initialState }),
  mutations,
  getters: {},
  actions: {},
}
