import { MoneyFormat } from '~/types/model/money-format'

/**
 * Convert number to money
 *
 * @example
 * money(1000000.012, MoneyFormat.IDR)
 * // => 'Rp 1.000.000,01'
 */
export default (input: number, format: MoneyFormat = MoneyFormat.IDR, maxFractionDigit: number = 2): string => {
  const defaultOption: Intl.NumberFormatOptions = {
    style: 'currency',
    minimumFractionDigits: 0,
    maximumFractionDigits: maxFractionDigit,
  }
  if (format === MoneyFormat.USD) {
    return input.toLocaleString('en-US', {
      ...defaultOption,
      currency: 'USD',
    })
  } else if (format === MoneyFormat.THOUSAND_COMMA) {
    return input
      .toLocaleString('en-US', {
        ...defaultOption,
        currency: 'USD',
      })
      .replace('$', '')
  } else if (format === MoneyFormat.IDR) {
    return (
      input
        .toLocaleString('id-ID', {
          ...defaultOption,
          currency: 'IDR',
        })
        .replace(/\s/g, '') || 'Rp0'
    )
  }
  return (
    input
      .toLocaleString('id-ID', {
        ...defaultOption,
        currency: 'IDR',
      })
      .replace(/\s/g, '')
      .replace('Rp', '') || 'Rp0'
  )
}
