import { Middleware } from '@nuxt/types'
import { startCase } from 'lodash'

import { availableStrategies, whitelistRouteName } from '~/constants/auth'

/**
 * For non-logged in user, this will allow them to switch between strategies
 * So the user will be redirected to the correct login pages based on path namespace
 */
const LoginRedirectorMiddleware: Middleware = context => {
  const paths = context.route.path.slice(1).split('/')
  const isRouteWhitelisted = whitelistRouteName.includes(`${context.route.name}`)

  const [namespace] = paths

  if (isRouteWhitelisted || context.$auth.loggedIn) return

  const strategy = context.$auth.strategy.name ?? ''

  if (strategy !== namespace && availableStrategies.includes(namespace)) {
    context.$auth.loginWith(`nv${startCase(namespace)}`)
  }
}

export default LoginRedirectorMiddleware
