import { Plugin } from '@nuxt/types'
import jsCookie from 'js-cookie'
import { v4 as uuidv4 } from 'uuid'

const plugin: Plugin = () => {
  if (!jsCookie.get('browser_id')) {
    jsCookie.set('browser_id', uuidv4())
  }

  if (!jsCookie.get('session_id')) {
    const browserId = jsCookie.get('browser_id')
    jsCookie.set('session_id', `${browserId}.${Date.now()}`)
  }
}

export default plugin
