// this is just a dummy store required for setting up nuxt auth
import { PublicCategory } from '@bukalapak/openapigen/products/mitrabangunan/product/v1/public'

export const state = () => ({
  publicProductCategories: [] as PublicCategory[],
  currentDomainName: '',
})

export const mutations = {
  setPublicProductCategories(state, newCategories: PublicCategory[]) {
    state.publicProductCategories = newCategories
  },
  setCurrentDomainName(state, domainName: string) {
    state.currentDomainName = domainName
  },
}

export const actions = {
  nuxtServerInit({ commit }, { req }) {
    commit('setCurrentDomainName', req.headers.host)
  },
}
