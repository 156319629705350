import { extend } from 'vee-validate'
import { double, ext, integer, max, min, numeric, required, size } from 'vee-validate/dist/rules'
import { ValidationRuleFunction } from 'vee-validate/dist/types/types'
import { MoneyFormat } from '~/types/model/money-format'
import moneyToNumber from '~/utils/converter/money-to-number'
import numberToMoney from '~/utils/converter/number-to-money'
import './custom-validators'

const defaultFieldName = '{field}'

extend('ext', {
  ...ext,
  message: (field, params) => {
    const extensions = Object.keys(params)
      .filter(key => !Number.isNaN(Number.parseInt(key, 10)))
      .map(key => params[key].toUpperCase())
    return `File ${field} harus ${extensions.join(', ')}`
  },
})

extend('size', {
  ...size,
  message: (field, { size }) => {
    const hasDivision = size % 1024 > 0
    const sizeInMB = hasDivision ? (size / 1024).toFixed(2) : Math.floor(size / 1024)
    return `${field} harus kurang dari ${sizeInMB} MB` // TODO: consult with designer for proper error msg
  },
})

extend('dimensions_ge_than', {
  params: ['width', 'height'],
  validate: async (input: File[], { width, height }: Record<any, any>) => {
    const fileList = Array.isArray(input) ? input : [input]
    const results = await Promise.all(fileList.map(file => isImageNotLessThan(file, width, height)))
    return results.every(v => v)
  },
  // TODO: consult with designer for proper error msg
  message: (field, { width, height }) => `Ukuran ${field} tidak boleh lebih besar dari ${width}x${height}`,
})

function isImageNotLessThan(file: File, width, height) {
  return new Promise(resolve => {
    const image = new Image()
    image.onerror = () => resolve(false)
    image.onload = () => resolve(image.width >= width && image.height >= height)
    image.src = URL.createObjectURL(file)
  })
}

extend('phonelength', {
  validate: fieldValue => {
    return fieldValue.length >= 9 && fieldValue.length <= 14
  },
  message: 'No. handphone harus min 9 digit dan maks 14 digit',
})

const validateMaxVal: ValidationRuleFunction = (value, args: { max: string }) => {
  const inputtedVal = Number.parseFloat(value)
  const max = Number.parseFloat(args.max)
  return !Number.isNaN(inputtedVal) && inputtedVal <= max
}

extend('max_value_dweb', {
  params: ['max'],
  validate: validateMaxVal,
  message: (field, { max }) =>
    `${field} tidak boleh lebih dari ${numberToMoney(Number.parseFloat(max), MoneyFormat.THOUSAND_DOTTED)}`,
})

extend('max_value_mweb', {
  params: ['max'],
  validate: validateMaxVal,
  message: (_, { max }) => `Tidak boleh lebih dari ${max}`,
})

const validateMinVal: ValidationRuleFunction = (value, args: { min: string }) => {
  const inputtedVal = Number.parseFloat(value)
  const min = Number.parseFloat(args.min)
  return !Number.isNaN(inputtedVal) && inputtedVal >= min
}

extend('min_value_dweb', {
  params: ['min'],
  validate: validateMinVal,
  message: (field, { min }) => `${field} tidak boleh kurang dari ${min}`,
})

extend('min_value_mweb', {
  params: ['min'],
  validate: validateMinVal,
  message: (_, { min }) => `Tidak boleh kurang dari ${min}`,
})

extend('integer', {
  ...integer,
  message: field => `${field} harus berupa angka`, // TODO: consult with designer for proper error msg
})

extend('integer_mweb', {
  ...integer,
  message: () => 'Harus berupa angka',
})

extend('double_mweb', {
  validate: value => !Number.isNaN(Number.parseFloat(value.toString().replace(',', '.'))),
  message: () => 'Harus angka atau bilangan desimal',
})

extend('price_idr_mweb', {
  validate: value => moneyToNumber(value) !== undefined,
  message: () => 'Harus angka dan titik (.)',
})

// TODO: add support for dweb
extend('less_than_equal', {
  params: ['target'],
  validate(value, { target }: Record<any, any>) {
    return Number.parseFloat(value) <= Number.parseFloat(target)
  },
  message: (_, { target }) => `Tidak boleh lebih dari ${target}`,
})

extend('less_than', {
  params: ['target'],
  validate(value, { target }: Record<any, any>) {
    return Number.parseFloat(value) < Number.parseFloat(target)
  },
  message: (_, { target }) => `Tidak boleh lebih atau sama dengan ${target}`,
})

extend('required_mweb', {
  ...required,
  message: () => 'Wajib diisi',
})

extend('numeric', {
  ...numeric,
  message: field => (field ? `${field} harus angka` : 'Harus berupa angka'),
})

extend('required', {
  ...required,
  message: field => `${field === defaultFieldName ? 'Kolom' : field} harus diisi`,
})

extend('decimal_number', {
  validate: value => /^\d*\.?\d*$/.test(value),
  message: field => (field ? `${field} harus angka atau bilangan desimal` : 'Harus berupa angka atau bilangan desimal'),
})

extend('min_char', {
  ...min,
  params: ['length', 'unit'],
  message: (field, { length, unit }) => `${field} min. ${length} ${unit}`,
})

extend('max_char', {
  ...max,
  params: ['length', 'unit'],
  message: (field, { length, unit }) => `${field} maks. ${length} ${unit}`,
})

extend('url', {
  validate: fieldValue => {
    try {
      const url = new URL(fieldValue)
      if (url) return true
      return false
    } catch (error) {
      return false
    }
  },
  message: () => 'URL tidak valid',
})

// TODO: DEPRECATED, still being used by seller/withdrawal/index.vue
extend('min_value_idr', {
  params: ['min'],
  validate: (fieldValue, params: { min: string }) => {
    const value = Number.parseInt(fieldValue.replaceAll('.', ''))
    const min = Number.parseInt(params.min)
    return value >= min
  },
  message: (_, params) => {
    const parsedNumber = Number.parseInt(params.min)
    if (Number.isNaN(parsedNumber)) {
      return 'Jumlahnya min. -'
    } else {
      return `Jumlahnya min. ${numberToMoney(parsedNumber, MoneyFormat.IDR)}`
    }
  },
})

// TODO: DEPRECATED, still being used by seller/withdrawal/index.vue
extend('max_value_idr', {
  params: ['max', 'message'],
  validate: (fieldValue, params: { max: string }) => {
    const value = Number.parseInt(fieldValue.replaceAll('.', ''))
    const max = Number.parseInt(params.max)
    return value <= max
  },
  message: (_, params) => {
    const message = params.message
    if (message) return message
    const parsedNumber = Number.parseInt(params.max)
    if (Number.isNaN(parsedNumber)) {
      return 'Jumlahnya max. -'
    } else {
      return `Jumlahnya max. ${numberToMoney(parsedNumber, MoneyFormat.IDR)}`
    }
  },
})

extend('double', {
  ...double,
  message: (field: string, params: Record<string, any>) =>
    `${field} hanya bisa ${params.decimals} angka di belakang koma`,
})
