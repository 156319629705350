import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _93e7ac78 = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _6b48c1e1 = () => interopDefault(import('../pages/artikel/index.vue' /* webpackChunkName: "pages/artikel/index" */))
const _1756ba82 = () => interopDefault(import('../pages/cct_trigger.vue' /* webpackChunkName: "pages/cct_trigger" */))
const _53815a70 = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _bbd0d9c6 = () => interopDefault(import('../pages/home.vue' /* webpackChunkName: "pages/home" */))
const _a0218544 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _aea2c7d8 = () => interopDefault(import('../pages/login_trigger.vue' /* webpackChunkName: "pages/login_trigger" */))
const _664834c8 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _36adc67e = () => interopDefault(import('../pages/logout_trigger.vue' /* webpackChunkName: "pages/logout_trigger" */))
const _b8c126aa = () => interopDefault(import('../pages/oauth_callback.vue' /* webpackChunkName: "pages/oauth_callback" */))
const _20daedd5 = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _73c851b5 = () => interopDefault(import('../pages/private.vue' /* webpackChunkName: "pages/private" */))
const _28b73693 = () => interopDefault(import('../pages/sell/index.vue' /* webpackChunkName: "pages/sell/index" */))
const _5d4f1100 = () => interopDefault(import('../pages/seller/index.vue' /* webpackChunkName: "pages/seller/index" */))
const _472ff132 = () => interopDefault(import('../pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _5aea51bc = () => interopDefault(import('../pages/admin/brand/index.vue' /* webpackChunkName: "pages/admin/brand/index" */))
const _ad8b1bde = () => interopDefault(import('../pages/admin/campaign/index.vue' /* webpackChunkName: "pages/admin/campaign/index" */))
const _5cb70640 = () => interopDefault(import('../pages/admin/campaign-attribute/index.vue' /* webpackChunkName: "pages/admin/campaign-attribute/index" */))
const _8fe212c6 = () => interopDefault(import('../pages/admin/categories/index.vue' /* webpackChunkName: "pages/admin/categories/index" */))
const _9f72433c = () => interopDefault(import('../pages/admin/courier/index.vue' /* webpackChunkName: "pages/admin/courier/index" */))
const _5bac356a = () => interopDefault(import('../pages/admin/create-new-password.vue' /* webpackChunkName: "pages/admin/create-new-password" */))
const _34a878f7 = () => interopDefault(import('../pages/admin/forgot-password.vue' /* webpackChunkName: "pages/admin/forgot-password" */))
const _d44a7e7a = () => interopDefault(import('../pages/admin/order/index.vue' /* webpackChunkName: "pages/admin/order/index" */))
const _3f37e9d6 = () => interopDefault(import('../pages/admin/otp-confirmation.vue' /* webpackChunkName: "pages/admin/otp-confirmation" */))
const _53dc72e5 = () => interopDefault(import('../pages/admin/paylater/index.vue' /* webpackChunkName: "pages/admin/paylater/index" */))
const _4192f3da = () => interopDefault(import('../pages/admin/producer/index.vue' /* webpackChunkName: "pages/admin/producer/index" */))
const _30f82d36 = () => interopDefault(import('../pages/admin/products/index.vue' /* webpackChunkName: "pages/admin/products/index" */))
const _7929f980 = () => interopDefault(import('../pages/admin/seller/index.vue' /* webpackChunkName: "pages/admin/seller/index" */))
const _6eb3e367 = () => interopDefault(import('../pages/admin/selling-product/index.vue' /* webpackChunkName: "pages/admin/selling-product/index" */))
const _1411520c = () => interopDefault(import('../pages/admin/user/index.vue' /* webpackChunkName: "pages/admin/user/index" */))
const _0c0e373a = () => interopDefault(import('../pages/admin/wallet/index.vue' /* webpackChunkName: "pages/admin/wallet/index" */))
const _4af4e8f8 = () => interopDefault(import('../pages/admin/warehouse/index.vue' /* webpackChunkName: "pages/admin/warehouse/index" */))
const _20e24f22 = () => interopDefault(import('../pages/buyer/faq/index.vue' /* webpackChunkName: "pages/buyer/faq/index" */))
const _1ff04d56 = () => interopDefault(import('../pages/buyer/wallet/index.vue' /* webpackChunkName: "pages/buyer/wallet/index" */))
const _0489abdc = () => interopDefault(import('../pages/buyer/withdrawal/index.vue' /* webpackChunkName: "pages/buyer/withdrawal/index" */))
const _26d31eb9 = () => interopDefault(import('../pages/dummy/sample_validation.vue' /* webpackChunkName: "pages/dummy/sample_validation" */))
const _2c11679e = () => interopDefault(import('../pages/login/admin/index.vue' /* webpackChunkName: "pages/login/admin/index" */))
const _2ed08dfc = () => interopDefault(import('../pages/login/buyer/index.vue' /* webpackChunkName: "pages/login/buyer/index" */))
const _b01ce334 = () => interopDefault(import('../pages/login/seller/index.vue' /* webpackChunkName: "pages/login/seller/index" */))
const _3c16f901 = () => interopDefault(import('../pages/sell/select-warehouse.vue' /* webpackChunkName: "pages/sell/select-warehouse" */))
const _5bfbdaa8 = () => interopDefault(import('../pages/sell/set-stock.vue' /* webpackChunkName: "pages/sell/set-stock" */))
const _1eae7b70 = () => interopDefault(import('../pages/seller/notification/index.vue' /* webpackChunkName: "pages/seller/notification/index" */))
const _5e6934ae = () => interopDefault(import('../pages/seller/products/index.vue' /* webpackChunkName: "pages/seller/products/index" */))
const _50a72da8 = () => interopDefault(import('../pages/seller/settings/index.vue' /* webpackChunkName: "pages/seller/settings/index" */))
const _829e0be2 = () => interopDefault(import('../pages/seller/transaction/index.vue' /* webpackChunkName: "pages/seller/transaction/index" */))
const _c2601704 = () => interopDefault(import('../pages/seller/wallet/index.vue' /* webpackChunkName: "pages/seller/wallet/index" */))
const _28a2fd3a = () => interopDefault(import('../pages/seller/withdrawal/index.vue' /* webpackChunkName: "pages/seller/withdrawal/index" */))
const _274462ea = () => interopDefault(import('../pages/admin/brand/add.vue' /* webpackChunkName: "pages/admin/brand/add" */))
const _b46fca66 = () => interopDefault(import('../pages/admin/campaign/create.vue' /* webpackChunkName: "pages/admin/campaign/create" */))
const _51f5266f = () => interopDefault(import('../pages/admin/campaign/create/1.vue' /* webpackChunkName: "pages/admin/campaign/create/1" */))
const _52033df0 = () => interopDefault(import('../pages/admin/campaign/create/2.vue' /* webpackChunkName: "pages/admin/campaign/create/2" */))
const _52115571 = () => interopDefault(import('../pages/admin/campaign/create/3.vue' /* webpackChunkName: "pages/admin/campaign/create/3" */))
const _521f6cf2 = () => interopDefault(import('../pages/admin/campaign/create/4.vue' /* webpackChunkName: "pages/admin/campaign/create/4" */))
const _667837ac = () => interopDefault(import('../pages/admin/categories/add.vue' /* webpackChunkName: "pages/admin/categories/add" */))
const _1455650f = () => interopDefault(import('../pages/admin/categories/edit.vue' /* webpackChunkName: "pages/admin/categories/edit" */))
const _3add576a = () => interopDefault(import('../pages/admin/categories/subcategories/index.vue' /* webpackChunkName: "pages/admin/categories/subcategories/index" */))
const _3ee02fc1 = () => interopDefault(import('../pages/admin/feature/announcement/index.vue' /* webpackChunkName: "pages/admin/feature/announcement/index" */))
const _59c44aa6 = () => interopDefault(import('../pages/admin/feature/banner/index.vue' /* webpackChunkName: "pages/admin/feature/banner/index" */))
const _fc0a69ea = () => interopDefault(import('../pages/admin/finance/withdrawal/index.vue' /* webpackChunkName: "pages/admin/finance/withdrawal/index" */))
const _35944c93 = () => interopDefault(import('../pages/admin/mofa/whitelist.vue' /* webpackChunkName: "pages/admin/mofa/whitelist" */))
const _443840c0 = () => interopDefault(import('../pages/admin/order/quotation/index.vue' /* webpackChunkName: "pages/admin/order/quotation/index" */))
const _363a3086 = () => interopDefault(import('../pages/admin/paylater/repayment.vue' /* webpackChunkName: "pages/admin/paylater/repayment" */))
const _16a55944 = () => interopDefault(import('../pages/admin/paylater/users/index.vue' /* webpackChunkName: "pages/admin/paylater/users/index" */))
const _2e092ca2 = () => interopDefault(import('../pages/admin/producer/add.vue' /* webpackChunkName: "pages/admin/producer/add" */))
const _09522bbe = () => interopDefault(import('../pages/admin/products/add-bulk/index.vue' /* webpackChunkName: "pages/admin/products/add-bulk/index" */))
const _1153d998 = () => interopDefault(import('../pages/admin/products/add-single/index.vue' /* webpackChunkName: "pages/admin/products/add-single/index" */))
const _ecb5dde2 = () => interopDefault(import('../pages/admin/seller/add.vue' /* webpackChunkName: "pages/admin/seller/add" */))
const _6dc38dfa = () => interopDefault(import('../pages/admin/selling-product/download.vue' /* webpackChunkName: "pages/admin/selling-product/download" */))
const _44f13334 = () => interopDefault(import('../pages/admin/selling-product/notification.vue' /* webpackChunkName: "pages/admin/selling-product/notification" */))
const _c145f672 = () => interopDefault(import('../pages/admin/warehouse/add.vue' /* webpackChunkName: "pages/admin/warehouse/add" */))
const _6d130257 = () => interopDefault(import('../pages/buyer/withdrawal/history/index.vue' /* webpackChunkName: "pages/buyer/withdrawal/history/index" */))
const _a8f465ce = () => interopDefault(import('../pages/dummy/sentry/bad-render.vue' /* webpackChunkName: "pages/dummy/sentry/bad-render" */))
const _701a24c6 = () => interopDefault(import('../pages/dummy/sentry/error.vue' /* webpackChunkName: "pages/dummy/sentry/error" */))
const _4f79693d = () => interopDefault(import('../pages/dummy/sentry/general.vue' /* webpackChunkName: "pages/dummy/sentry/general" */))
const _1cae2474 = () => interopDefault(import('../pages/seller/products/filter/index.vue' /* webpackChunkName: "pages/seller/products/filter/index" */))
const _d9e058a6 = () => interopDefault(import('../pages/seller/reset-password/confirm-phone.vue' /* webpackChunkName: "pages/seller/reset-password/confirm-phone" */))
const _06ded6cc = () => interopDefault(import('../pages/seller/reset-password/new-password.vue' /* webpackChunkName: "pages/seller/reset-password/new-password" */))
const _0c6d59fb = () => interopDefault(import('../pages/seller/reset-password/verify-otp.vue' /* webpackChunkName: "pages/seller/reset-password/verify-otp" */))
const _53292d7f = () => interopDefault(import('../pages/seller/withdrawal/history/index.vue' /* webpackChunkName: "pages/seller/withdrawal/history/index" */))
const _b1db308e = () => interopDefault(import('../pages/admin/categories/subcategories/add.vue' /* webpackChunkName: "pages/admin/categories/subcategories/add" */))
const _067954d8 = () => interopDefault(import('../pages/admin/feature/banner/create.vue' /* webpackChunkName: "pages/admin/feature/banner/create" */))
const _65510774 = () => interopDefault(import('../pages/admin/order/quotation/form.vue' /* webpackChunkName: "pages/admin/order/quotation/form" */))
const _016f313e = () => interopDefault(import('../pages/buyer/withdrawal/bank/add.vue' /* webpackChunkName: "pages/buyer/withdrawal/bank/add" */))
const _4cac7aaa = () => interopDefault(import('../pages/buyer/withdrawal/bank/empty.vue' /* webpackChunkName: "pages/buyer/withdrawal/bank/empty" */))
const _d479eb68 = () => interopDefault(import('../pages/seller/products/filter/category.vue' /* webpackChunkName: "pages/seller/products/filter/category" */))
const _c5c62de2 = () => interopDefault(import('../pages/seller/products/filter/discount.vue' /* webpackChunkName: "pages/seller/products/filter/discount" */))
const _63c8cd2a = () => interopDefault(import('../pages/seller/products/filter/price.vue' /* webpackChunkName: "pages/seller/products/filter/price" */))
const _0db6a5d4 = () => interopDefault(import('../pages/seller/withdrawal/bank/add.vue' /* webpackChunkName: "pages/seller/withdrawal/bank/add" */))
const _2cf26082 = () => interopDefault(import('../pages/seller/withdrawal/bank/empty.vue' /* webpackChunkName: "pages/seller/withdrawal/bank/empty" */))
const _6f82fbab = () => interopDefault(import('../pages/admin/order/quotation/_id/index.vue' /* webpackChunkName: "pages/admin/order/quotation/_id/index" */))
const _c1a39512 = () => interopDefault(import('../pages/seller/products/warehouses/_id/index.vue' /* webpackChunkName: "pages/seller/products/warehouses/_id/index" */))
const _046df757 = () => interopDefault(import('../pages/admin/categories/subcategories/_id/edit.vue' /* webpackChunkName: "pages/admin/categories/subcategories/_id/edit" */))
const _22596a9b = () => interopDefault(import('../pages/admin/feature/banner/_id/edit.vue' /* webpackChunkName: "pages/admin/feature/banner/_id/edit" */))
const _dc0966a6 = () => interopDefault(import('../pages/admin/feature/banner/_id/voucher.vue' /* webpackChunkName: "pages/admin/feature/banner/_id/voucher" */))
const _c395bf90 = () => interopDefault(import('../pages/admin/finance/withdrawal/_id/history.vue' /* webpackChunkName: "pages/admin/finance/withdrawal/_id/history" */))
const _26dd9726 = () => interopDefault(import('../pages/admin/paylater/users/_id/edit/index.vue' /* webpackChunkName: "pages/admin/paylater/users/_id/edit/index" */))
const _51d83fb1 = () => interopDefault(import('../pages/admin/paylater/users/_id/transactions/index.vue' /* webpackChunkName: "pages/admin/paylater/users/_id/transactions/index" */))
const _e5e85192 = () => interopDefault(import('../pages/buyer/withdrawal/bank/_bank_id/edit.vue' /* webpackChunkName: "pages/buyer/withdrawal/bank/_bank_id/edit" */))
const _ad4b35e2 = () => interopDefault(import('../pages/seller/withdrawal/bank/_bank_id/edit.vue' /* webpackChunkName: "pages/seller/withdrawal/bank/_bank_id/edit" */))
const _2c0fc22e = () => interopDefault(import('../pages/admin/order/_id/index.vue' /* webpackChunkName: "pages/admin/order/_id/index" */))
const _84606c60 = () => interopDefault(import('../pages/admin/products/_id/index.vue' /* webpackChunkName: "pages/admin/products/_id/index" */))
const _31d5f7a6 = () => interopDefault(import('../pages/artikel/c/_slug.vue' /* webpackChunkName: "pages/artikel/c/_slug" */))
const _244f91b2 = () => interopDefault(import('../pages/buyer/faq/_slug.vue' /* webpackChunkName: "pages/buyer/faq/_slug" */))
const _6fecebfd = () => interopDefault(import('../pages/buyer/withdrawal/_id/index.vue' /* webpackChunkName: "pages/buyer/withdrawal/_id/index" */))
const _6ee6eb25 = () => interopDefault(import('../pages/seller/withdrawal/_id/index.vue' /* webpackChunkName: "pages/seller/withdrawal/_id/index" */))
const _6aa7f876 = () => interopDefault(import('../pages/admin/brand/_id/edit.vue' /* webpackChunkName: "pages/admin/brand/_id/edit" */))
const _290e8750 = () => interopDefault(import('../pages/admin/campaign/_id/edit.vue' /* webpackChunkName: "pages/admin/campaign/_id/edit" */))
const _1d527e32 = () => interopDefault(import('../pages/admin/campaign/_id/edit/1.vue' /* webpackChunkName: "pages/admin/campaign/_id/edit/1" */))
const _1d6095b3 = () => interopDefault(import('../pages/admin/campaign/_id/edit/2.vue' /* webpackChunkName: "pages/admin/campaign/_id/edit/2" */))
const _1d6ead34 = () => interopDefault(import('../pages/admin/campaign/_id/edit/3.vue' /* webpackChunkName: "pages/admin/campaign/_id/edit/3" */))
const _1d7cc4b5 = () => interopDefault(import('../pages/admin/campaign/_id/edit/4.vue' /* webpackChunkName: "pages/admin/campaign/_id/edit/4" */))
const _ea446a48 = () => interopDefault(import('../pages/admin/campaign/_id/histories.vue' /* webpackChunkName: "pages/admin/campaign/_id/histories" */))
const _21d597fc = () => interopDefault(import('../pages/admin/courier/_id/tariff/index.vue' /* webpackChunkName: "pages/admin/courier/_id/tariff/index" */))
const _345038cc = () => interopDefault(import('../pages/admin/order/_id/delivery-order/index.vue' /* webpackChunkName: "pages/admin/order/_id/delivery-order/index" */))
const _b2ce183e = () => interopDefault(import('../pages/admin/order/_id/set-done.vue' /* webpackChunkName: "pages/admin/order/_id/set-done" */))
const _6aaadc0e = () => interopDefault(import('../pages/admin/producer/_id/edit.vue' /* webpackChunkName: "pages/admin/producer/_id/edit" */))
const _df384108 = () => interopDefault(import('../pages/admin/products/_id/edit.vue' /* webpackChunkName: "pages/admin/products/_id/edit" */))
const _3a44987e = () => interopDefault(import('../pages/admin/seller/_id/edit.vue' /* webpackChunkName: "pages/admin/seller/_id/edit" */))
const _a696976c = () => interopDefault(import('../pages/admin/user/_id/bank-account/index.vue' /* webpackChunkName: "pages/admin/user/_id/bank-account/index" */))
const _4cbc9b0e = () => interopDefault(import('../pages/admin/wallet/_account_id/history.vue' /* webpackChunkName: "pages/admin/wallet/_account_id/history" */))
const _a9bd59ee = () => interopDefault(import('../pages/admin/warehouse/_id/edit.vue' /* webpackChunkName: "pages/admin/warehouse/_id/edit" */))
const _8c283522 = () => interopDefault(import('../pages/seller/products/_id/discount.vue' /* webpackChunkName: "pages/seller/products/_id/discount" */))
const _f38c6690 = () => interopDefault(import('../pages/seller/products/_id/edit.vue' /* webpackChunkName: "pages/seller/products/_id/edit" */))
const _0c810400 = () => interopDefault(import('../pages/seller/transaction/_id/delivery-order/index.vue' /* webpackChunkName: "pages/seller/transaction/_id/delivery-order/index" */))
const _bddd6d4e = () => interopDefault(import('../pages/seller/transaction/_id/detail.vue' /* webpackChunkName: "pages/seller/transaction/_id/detail" */))
const _12e4fc3c = () => interopDefault(import('../pages/seller/transaction/_id/history.vue' /* webpackChunkName: "pages/seller/transaction/_id/history" */))
const _1434b66a = () => interopDefault(import('../pages/admin/courier/_id/tariff/add.vue' /* webpackChunkName: "pages/admin/courier/_id/tariff/add" */))
const _f30db8f8 = () => interopDefault(import('../pages/admin/courier/_id/tariff/add-bulk.vue' /* webpackChunkName: "pages/admin/courier/_id/tariff/add-bulk" */))
const _c5de57c8 = () => interopDefault(import('../pages/admin/courier/_id/tariff/_tariff/edit.vue' /* webpackChunkName: "pages/admin/courier/_id/tariff/_tariff/edit" */))
const _ad6bffda = () => interopDefault(import('../pages/admin/user/_id/bank-account/_bankId/history.vue' /* webpackChunkName: "pages/admin/user/_id/bank-account/_bankId/history" */))
const _56bc891a = () => interopDefault(import('../pages/admin/products/_id/_sellingProductId/index.vue' /* webpackChunkName: "pages/admin/products/_id/_sellingProductId/index" */))
const _efa06eee = () => interopDefault(import('../pages/artikel/_id.vue' /* webpackChunkName: "pages/artikel/_id" */))
const _124cec26 = () => interopDefault(import('../pages/c/_category/index.vue' /* webpackChunkName: "pages/c/_category/index" */))
const _328fd59e = () => interopDefault(import('../pages/c/_category/_subcategory/index.vue' /* webpackChunkName: "pages/c/_category/_subcategory/index" */))
const _15cfd7f8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _76b18fcc = () => interopDefault(import('../pages/_method/login.vue' /* webpackChunkName: "pages/_method/login" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admin",
    component: _93e7ac78,
    name: "admin"
  }, {
    path: "/artikel",
    component: _6b48c1e1,
    name: "artikel"
  }, {
    path: "/cct_trigger",
    component: _1756ba82,
    name: "cct_trigger"
  }, {
    path: "/faq",
    component: _53815a70,
    name: "faq"
  }, {
    path: "/home",
    component: _bbd0d9c6,
    name: "home"
  }, {
    path: "/login",
    component: _a0218544,
    name: "login"
  }, {
    path: "/login_trigger",
    component: _aea2c7d8,
    name: "login_trigger"
  }, {
    path: "/logout",
    component: _664834c8,
    name: "logout"
  }, {
    path: "/logout_trigger",
    component: _36adc67e,
    name: "logout_trigger"
  }, {
    path: "/oauth_callback",
    component: _b8c126aa,
    name: "oauth_callback"
  }, {
    path: "/privacy-policy",
    component: _20daedd5,
    name: "privacy-policy"
  }, {
    path: "/private",
    component: _73c851b5,
    name: "private"
  }, {
    path: "/sell",
    component: _28b73693,
    name: "sell"
  }, {
    path: "/seller",
    component: _5d4f1100,
    name: "seller"
  }, {
    path: "/terms-and-conditions",
    component: _472ff132,
    name: "terms-and-conditions"
  }, {
    path: "/admin/brand",
    component: _5aea51bc,
    name: "admin-brand"
  }, {
    path: "/admin/campaign",
    component: _ad8b1bde,
    name: "admin-campaign"
  }, {
    path: "/admin/campaign-attribute",
    component: _5cb70640,
    name: "admin-campaign-attribute"
  }, {
    path: "/admin/categories",
    component: _8fe212c6,
    name: "admin-categories"
  }, {
    path: "/admin/courier",
    component: _9f72433c,
    name: "admin-courier"
  }, {
    path: "/admin/create-new-password",
    component: _5bac356a,
    name: "admin-create-new-password"
  }, {
    path: "/admin/forgot-password",
    component: _34a878f7,
    name: "admin-forgot-password"
  }, {
    path: "/admin/order",
    component: _d44a7e7a,
    name: "admin-order"
  }, {
    path: "/admin/otp-confirmation",
    component: _3f37e9d6,
    name: "admin-otp-confirmation"
  }, {
    path: "/admin/paylater",
    component: _53dc72e5,
    name: "admin-paylater"
  }, {
    path: "/admin/producer",
    component: _4192f3da,
    name: "admin-producer"
  }, {
    path: "/admin/products",
    component: _30f82d36,
    name: "admin-products"
  }, {
    path: "/admin/seller",
    component: _7929f980,
    name: "admin-seller"
  }, {
    path: "/admin/selling-product",
    component: _6eb3e367,
    name: "admin-selling-product"
  }, {
    path: "/admin/user",
    component: _1411520c,
    name: "admin-user"
  }, {
    path: "/admin/wallet",
    component: _0c0e373a,
    name: "admin-wallet"
  }, {
    path: "/admin/warehouse",
    component: _4af4e8f8,
    name: "admin-warehouse"
  }, {
    path: "/buyer/faq",
    component: _20e24f22,
    name: "buyer-faq"
  }, {
    path: "/buyer/wallet",
    component: _1ff04d56,
    name: "buyer-wallet"
  }, {
    path: "/buyer/withdrawal",
    component: _0489abdc,
    name: "buyer-withdrawal"
  }, {
    path: "/dummy/sample_validation",
    component: _26d31eb9,
    name: "dummy-sample_validation"
  }, {
    path: "/login/admin",
    component: _2c11679e,
    name: "login-admin"
  }, {
    path: "/login/buyer",
    component: _2ed08dfc,
    name: "login-buyer"
  }, {
    path: "/login/seller",
    component: _b01ce334,
    name: "login-seller"
  }, {
    path: "/sell/select-warehouse",
    component: _3c16f901,
    name: "sell-select-warehouse"
  }, {
    path: "/sell/set-stock",
    component: _5bfbdaa8,
    name: "sell-set-stock"
  }, {
    path: "/seller/notification",
    component: _1eae7b70,
    name: "seller-notification"
  }, {
    path: "/seller/products",
    component: _5e6934ae,
    name: "seller-products"
  }, {
    path: "/seller/settings",
    component: _50a72da8,
    name: "seller-settings"
  }, {
    path: "/seller/transaction",
    component: _829e0be2,
    name: "seller-transaction"
  }, {
    path: "/seller/wallet",
    component: _c2601704,
    name: "seller-wallet"
  }, {
    path: "/seller/withdrawal",
    component: _28a2fd3a,
    name: "seller-withdrawal"
  }, {
    path: "/admin/brand/add",
    component: _274462ea,
    name: "admin-brand-add"
  }, {
    path: "/admin/campaign/create",
    component: _b46fca66,
    name: "admin-campaign-create",
    children: [{
      path: "1",
      component: _51f5266f,
      name: "admin-campaign-create-1"
    }, {
      path: "2",
      component: _52033df0,
      name: "admin-campaign-create-2"
    }, {
      path: "3",
      component: _52115571,
      name: "admin-campaign-create-3"
    }, {
      path: "4",
      component: _521f6cf2,
      name: "admin-campaign-create-4"
    }]
  }, {
    path: "/admin/categories/add",
    component: _667837ac,
    name: "admin-categories-add"
  }, {
    path: "/admin/categories/edit",
    component: _1455650f,
    name: "admin-categories-edit"
  }, {
    path: "/admin/categories/subcategories",
    component: _3add576a,
    name: "admin-categories-subcategories"
  }, {
    path: "/admin/feature/announcement",
    component: _3ee02fc1,
    name: "admin-feature-announcement"
  }, {
    path: "/admin/feature/banner",
    component: _59c44aa6,
    name: "admin-feature-banner"
  }, {
    path: "/admin/finance/withdrawal",
    component: _fc0a69ea,
    name: "admin-finance-withdrawal"
  }, {
    path: "/admin/mofa/whitelist",
    component: _35944c93,
    name: "admin-mofa-whitelist"
  }, {
    path: "/admin/order/quotation",
    component: _443840c0,
    name: "admin-order-quotation"
  }, {
    path: "/admin/paylater/repayment",
    component: _363a3086,
    name: "admin-paylater-repayment"
  }, {
    path: "/admin/paylater/users",
    component: _16a55944,
    name: "admin-paylater-users"
  }, {
    path: "/admin/producer/add",
    component: _2e092ca2,
    name: "admin-producer-add"
  }, {
    path: "/admin/products/add-bulk",
    component: _09522bbe,
    name: "admin-products-add-bulk"
  }, {
    path: "/admin/products/add-single",
    component: _1153d998,
    name: "admin-products-add-single"
  }, {
    path: "/admin/seller/add",
    component: _ecb5dde2,
    name: "admin-seller-add"
  }, {
    path: "/admin/selling-product/download",
    component: _6dc38dfa,
    name: "admin-selling-product-download"
  }, {
    path: "/admin/selling-product/notification",
    component: _44f13334,
    name: "admin-selling-product-notification"
  }, {
    path: "/admin/warehouse/add",
    component: _c145f672,
    name: "admin-warehouse-add"
  }, {
    path: "/buyer/withdrawal/history",
    component: _6d130257,
    name: "buyer-withdrawal-history"
  }, {
    path: "/dummy/sentry/bad-render",
    component: _a8f465ce,
    name: "dummy-sentry-bad-render"
  }, {
    path: "/dummy/sentry/error",
    component: _701a24c6,
    name: "dummy-sentry-error"
  }, {
    path: "/dummy/sentry/general",
    component: _4f79693d,
    name: "dummy-sentry-general"
  }, {
    path: "/seller/products/filter",
    component: _1cae2474,
    name: "seller-products-filter"
  }, {
    path: "/seller/reset-password/confirm-phone",
    component: _d9e058a6,
    name: "seller-reset-password-confirm-phone"
  }, {
    path: "/seller/reset-password/new-password",
    component: _06ded6cc,
    name: "seller-reset-password-new-password"
  }, {
    path: "/seller/reset-password/verify-otp",
    component: _0c6d59fb,
    name: "seller-reset-password-verify-otp"
  }, {
    path: "/seller/withdrawal/history",
    component: _53292d7f,
    name: "seller-withdrawal-history"
  }, {
    path: "/admin/categories/subcategories/add",
    component: _b1db308e,
    name: "admin-categories-subcategories-add"
  }, {
    path: "/admin/feature/banner/create",
    component: _067954d8,
    name: "admin-feature-banner-create"
  }, {
    path: "/admin/order/quotation/form",
    component: _65510774,
    name: "admin-order-quotation-form"
  }, {
    path: "/buyer/withdrawal/bank/add",
    component: _016f313e,
    name: "buyer-withdrawal-bank-add"
  }, {
    path: "/buyer/withdrawal/bank/empty",
    component: _4cac7aaa,
    name: "buyer-withdrawal-bank-empty"
  }, {
    path: "/seller/products/filter/category",
    component: _d479eb68,
    name: "seller-products-filter-category"
  }, {
    path: "/seller/products/filter/discount",
    component: _c5c62de2,
    name: "seller-products-filter-discount"
  }, {
    path: "/seller/products/filter/price",
    component: _63c8cd2a,
    name: "seller-products-filter-price"
  }, {
    path: "/seller/withdrawal/bank/add",
    component: _0db6a5d4,
    name: "seller-withdrawal-bank-add"
  }, {
    path: "/seller/withdrawal/bank/empty",
    component: _2cf26082,
    name: "seller-withdrawal-bank-empty"
  }, {
    path: "/admin/order/quotation/:id",
    component: _6f82fbab,
    name: "admin-order-quotation-id"
  }, {
    path: "/seller/products/warehouses/:id",
    component: _c1a39512,
    name: "seller-products-warehouses-id"
  }, {
    path: "/admin/categories/subcategories/:id/edit",
    component: _046df757,
    name: "admin-categories-subcategories-id-edit"
  }, {
    path: "/admin/feature/banner/:id/edit",
    component: _22596a9b,
    name: "admin-feature-banner-id-edit"
  }, {
    path: "/admin/feature/banner/:id/voucher",
    component: _dc0966a6,
    name: "admin-feature-banner-id-voucher"
  }, {
    path: "/admin/finance/withdrawal/:id/history",
    component: _c395bf90,
    name: "admin-finance-withdrawal-id-history"
  }, {
    path: "/admin/paylater/users/:id/edit",
    component: _26dd9726,
    name: "admin-paylater-users-id-edit"
  }, {
    path: "/admin/paylater/users/:id/transactions",
    component: _51d83fb1,
    name: "admin-paylater-users-id-transactions"
  }, {
    path: "/buyer/withdrawal/bank/:bank_id?/edit",
    component: _e5e85192,
    name: "buyer-withdrawal-bank-bank_id-edit"
  }, {
    path: "/seller/withdrawal/bank/:bank_id?/edit",
    component: _ad4b35e2,
    name: "seller-withdrawal-bank-bank_id-edit"
  }, {
    path: "/admin/order/:id",
    component: _2c0fc22e,
    name: "admin-order-id"
  }, {
    path: "/admin/products/:id",
    component: _84606c60,
    name: "admin-products-id"
  }, {
    path: "/artikel/c/:slug?",
    component: _31d5f7a6,
    name: "artikel-c-slug"
  }, {
    path: "/buyer/faq/:slug",
    component: _244f91b2,
    name: "buyer-faq-slug"
  }, {
    path: "/buyer/withdrawal/:id",
    component: _6fecebfd,
    name: "buyer-withdrawal-id"
  }, {
    path: "/seller/withdrawal/:id",
    component: _6ee6eb25,
    name: "seller-withdrawal-id"
  }, {
    path: "/admin/brand/:id/edit",
    component: _6aa7f876,
    name: "admin-brand-id-edit"
  }, {
    path: "/admin/campaign/:id/edit",
    component: _290e8750,
    name: "admin-campaign-id-edit",
    children: [{
      path: "1",
      component: _1d527e32,
      name: "admin-campaign-id-edit-1"
    }, {
      path: "2",
      component: _1d6095b3,
      name: "admin-campaign-id-edit-2"
    }, {
      path: "3",
      component: _1d6ead34,
      name: "admin-campaign-id-edit-3"
    }, {
      path: "4",
      component: _1d7cc4b5,
      name: "admin-campaign-id-edit-4"
    }]
  }, {
    path: "/admin/campaign/:id/histories",
    component: _ea446a48,
    name: "admin-campaign-id-histories"
  }, {
    path: "/admin/courier/:id/tariff",
    component: _21d597fc,
    name: "admin-courier-id-tariff"
  }, {
    path: "/admin/order/:id/delivery-order",
    component: _345038cc,
    name: "admin-order-id-delivery-order"
  }, {
    path: "/admin/order/:id/set-done",
    component: _b2ce183e,
    name: "admin-order-id-set-done"
  }, {
    path: "/admin/producer/:id/edit",
    component: _6aaadc0e,
    name: "admin-producer-id-edit"
  }, {
    path: "/admin/products/:id/edit",
    component: _df384108,
    name: "admin-products-id-edit"
  }, {
    path: "/admin/seller/:id/edit",
    component: _3a44987e,
    name: "admin-seller-id-edit"
  }, {
    path: "/admin/user/:id/bank-account",
    component: _a696976c,
    name: "admin-user-id-bank-account"
  }, {
    path: "/admin/wallet/:account_id/history",
    component: _4cbc9b0e,
    name: "admin-wallet-account_id-history"
  }, {
    path: "/admin/warehouse/:id/edit",
    component: _a9bd59ee,
    name: "admin-warehouse-id-edit"
  }, {
    path: "/seller/products/:id/discount",
    component: _8c283522,
    name: "seller-products-id-discount"
  }, {
    path: "/seller/products/:id/edit",
    component: _f38c6690,
    name: "seller-products-id-edit"
  }, {
    path: "/seller/transaction/:id/delivery-order",
    component: _0c810400,
    name: "seller-transaction-id-delivery-order"
  }, {
    path: "/seller/transaction/:id/detail",
    component: _bddd6d4e,
    name: "seller-transaction-id-detail"
  }, {
    path: "/seller/transaction/:id/history",
    component: _12e4fc3c,
    name: "seller-transaction-id-history"
  }, {
    path: "/admin/courier/:id/tariff/add",
    component: _1434b66a,
    name: "admin-courier-id-tariff-add"
  }, {
    path: "/admin/courier/:id/tariff/add-bulk",
    component: _f30db8f8,
    name: "admin-courier-id-tariff-add-bulk"
  }, {
    path: "/admin/courier/:id/tariff/:tariff/edit",
    component: _c5de57c8,
    name: "admin-courier-id-tariff-tariff-edit"
  }, {
    path: "/admin/user/:id/bank-account/:bankId?/history",
    component: _ad6bffda,
    name: "admin-user-id-bank-account-bankId-history"
  }, {
    path: "/admin/products/:id/:sellingProductId",
    component: _56bc891a,
    name: "admin-products-id-sellingProductId"
  }, {
    path: "/artikel/:id",
    component: _efa06eee,
    name: "artikel-id"
  }, {
    path: "/c/:category",
    component: _124cec26,
    name: "c-category"
  }, {
    path: "/c/:category?/:subcategory",
    component: _328fd59e,
    name: "c-category-subcategory"
  }, {
    path: "/",
    component: _15cfd7f8,
    name: "index"
  }, {
    path: "/:method/login",
    component: _76b18fcc,
    name: "method-login"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
