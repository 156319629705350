import { PublicSellerWarehouse } from '@bukalapak/openapigen/products/mitrabangunan/product/v1/public/api'
import Vue from 'vue'
import type { MutationTree } from 'vuex'
import { SellerNewProductWarehouseSellData } from '~/types/component/seller-new-product-form-type'
import { SellerMasterProduct } from '~/types/model/product'

export type PagesSellState = {
  selectedProduct: SellerMasterProduct | null
  selectedWarehouses: PublicSellerWarehouse[]
  warehousesSellData: Record<string, SellerNewProductWarehouseSellData>
  sellerWarehouses: Record<string, PublicSellerWarehouse>
}

export type PagesSellVuexUpdateSellDataProps = {
  id: string
  data: {
    [key in keyof SellerNewProductWarehouseSellData]+?: SellerNewProductWarehouseSellData[key]
  }
}

const initialState: PagesSellState = {
  selectedProduct: null,
  selectedWarehouses: [],
  warehousesSellData: {},
  sellerWarehouses: {},
}

const mutations: MutationTree<PagesSellState> = {
  updateSelectedProduct(state, payload: SellerMasterProduct) {
    state.selectedProduct = payload
  },
  updateSelectedWarehouses(state, payload: PublicSellerWarehouse[]) {
    state.selectedWarehouses = payload || []
    state.warehousesSellData = payload.reduce(
      (output, warehouse) => {
        if (!(`${warehouse?.id}` in state.warehousesSellData)) {
          output[`${warehouse?.id}`] = { buyQtyMin: 1, buyQtyMultiplier: 1 }
        }
        return output
      },
      { ...state.warehousesSellData }
    ) as PagesSellState['warehousesSellData']
  },
  updateWarehouseSellData(state, { id, data }: PagesSellVuexUpdateSellDataProps) {
    if (state.selectedWarehouses.find(warehouse => warehouse.id === id)) {
      Vue.set(state.warehousesSellData, id, {
        ...state.warehousesSellData[id],
        ...data,
      })
    }
  },
  updateSellerWarehouses(state, payload: Record<string, PublicSellerWarehouse>) {
    state.sellerWarehouses = payload
  },
}

export default {
  namespaced: true,
  state: () => ({ ...initialState }),
  mutations,
  getters: {},
  actions: {},
}
