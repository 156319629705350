import { SellerMenuItem } from '~/types/component/seller-menu-item'

export enum SellerMenuKey {
  SELLER = 'seller',

  // product
  PRODUCTS = 'seller-products',
  PRODUCT_ID_EDIT = 'seller-products-id-edit',
  PRODUCT_ID_DISCOUNT = 'seller-products-id-discount',
  PRODUCT_FILTER = 'seller-products-filter',
  PRODUCT_FILTER_CATEGORY = 'seller-products-filter-category',
  PRODUCT_FILTER_DISCOUNT = 'seller-products-filter-discount',
  PRODUCT_FILTER_PRICE = 'seller-products-filter-price',

  // transaction
  TRANSACTION = 'seller-transaction',
  TRANSACTION_ID_DETAIL = 'seller-transaction-id-detail',
  TRANSACTION_ID_HISTORY = 'seller-transaction-id-history',
  TRANSACTION_ID_DELIVERY_ORDER = 'seller-transaction-id-delivery-order',

  // withdrawal
  WITHDRAWAL = 'seller-withdrawal',
  WITHDRAWAL_ID = 'seller-withdrawal-id',
  WITHDRAWAL_HISTORY = 'seller-withdrawal-history',
  WITHDRAWAL_BANK_ADD = 'seller-withdrawal-bank-add',
  WITHDRAWAL_BANK_EMPTY = 'seller-withdrawal-bank-empty',
  WITHDRAWAL_BANK_BANK_ID = 'seller-withdrawal-bank-bank-id-edit',

  WALLET = 'seller-wallet',

  SETTINGS = 'seller-settings',
  NOTIFICATION = 'seller-notification',

  SELL = 'sell',

  SELLER_LOGIN_FORGOT_PASSWORD = 'login-seller-forgot',
  SELLER_LOGIN_RESET_PASSWORD = 'login-seller-forgot-reset-password',
  SELLER_LOGIN_VERIFY_OTP = 'login-seller-forgot-verify-otp',

  SELLER_RESET_PASSWORD_CONFIRM_PHONE = 'seller-reset-password-confirm-phone',
  SELLER_RESET_PASSWORD_NEW_PASSWORD = 'seller-reset-password-new-password',
  SELLER_RESET_PASSWORD_VERIFY_OTP = 'seller-reset-password-verify-otp',
}

export const SellerMenus: Record<string, SellerMenuItem> = {
  [SellerMenuKey.SELLER]: {
    hideNavbar: true,
    bottomNavText: 'Home',
    routeName: SellerMenuKey.SELLER,
    bottomNavIcon: 'icoHome',
  },

  // ---- transaction ----
  [SellerMenuKey.TRANSACTION]: {
    title: 'Transaksi',
    hideBackButton: true,
    bottomNavText: 'Transaksi',
    routeName: SellerMenuKey.TRANSACTION,
    bottomNavIcon: 'icoTransaction',
  },
  [SellerMenuKey.TRANSACTION_ID_DETAIL]: {
    title: 'Detail Transaksi',
    routeName: SellerMenuKey.TRANSACTION_ID_DETAIL,
  },
  [SellerMenuKey.TRANSACTION_ID_HISTORY]: {
    title: 'Riwayat Status Transaksi',
    routeName: SellerMenuKey.TRANSACTION_ID_HISTORY,
  },
  [SellerMenuKey.TRANSACTION_ID_DELIVERY_ORDER]: {
    hideNavbar: true,
    routeName: SellerMenuKey.TRANSACTION_ID_DELIVERY_ORDER,
  },

  // ---- products ----
  [SellerMenuKey.PRODUCTS]: {
    title: 'Daftar Barang',
    customIcon: 'ico_add_minor',
    hideBackButton: true,
    bottomNavText: 'Barang',
    routeName: SellerMenuKey.PRODUCTS,
    bottomNavIcon: 'icoFeaturedproduct',
    screenTracker: ({ $route }) => ({
      screen_name: 'ProductList_Screen',
      screen_attribute: JSON.stringify({
        product_status_filter: $route.query.sellingStatus,
        warehouse: $route.query.warehouse,
        search_keyword: $route.query.keyword,
      }),
    }),
  },
  [SellerMenuKey.PRODUCT_ID_EDIT]: {
    title: 'Info Barang',
    routeName: SellerMenuKey.PRODUCT_ID_EDIT,
  },
  [SellerMenuKey.PRODUCT_FILTER]: {
    title: 'Filter',
    routeName: SellerMenuKey.PRODUCT_FILTER,
    screenTracker: () => ({
      screen_name: 'ProductFilter_Screen',
    }),
  },
  [SellerMenuKey.PRODUCT_FILTER_CATEGORY]: {
    title: 'Kategori Barang',
    routeName: SellerMenuKey.PRODUCT_FILTER_CATEGORY,
    screenTracker: () => ({
      screen_name: 'ProductCategoryFilter_Screen',
    }),
  },
  [SellerMenuKey.PRODUCT_FILTER_DISCOUNT]: {
    title: 'Diskon',
    routeName: SellerMenuKey.PRODUCT_FILTER_DISCOUNT,
    screenTracker: () => ({
      screen_name: 'ProductDiscountFilter_Screen',
    }),
  },
  [SellerMenuKey.PRODUCT_FILTER_PRICE]: {
    title: 'Filter Harga',
    routeName: SellerMenuKey.PRODUCT_FILTER_PRICE,
    screenTracker: () => ({
      screen_name: 'ProductPriceFilter_Screen',
    }),
  },
  [SellerMenuKey.PRODUCT_ID_DISCOUNT]: {
    title: 'Atur Diskon',
    routeName: SellerMenuKey.PRODUCT_ID_DISCOUNT,
    screenTracker: () => ({
      screen_name: 'ProductSetDiscount_Screen',
    }),
  },

  // ---- withdrawal ----
  [SellerMenuKey.WITHDRAWAL]: {
    title: 'Cairkan saldo',
    routeName: SellerMenuKey.WITHDRAWAL,
  },
  [SellerMenuKey.WITHDRAWAL_ID]: {
    title: 'Detail pencairan',
    routeName: SellerMenuKey.WITHDRAWAL_ID,
  },
  [SellerMenuKey.WITHDRAWAL_HISTORY]: {
    title: 'Riwayat pencairan',
    routeName: SellerMenuKey.WITHDRAWAL_HISTORY,
  },
  [SellerMenuKey.WITHDRAWAL_BANK_ADD]: {
    title: 'Tambah rekening',
    routeName: SellerMenuKey.WITHDRAWAL_BANK_ADD,
  },
  [SellerMenuKey.WITHDRAWAL_BANK_EMPTY]: {
    title: 'Cairkan saldo',
    routeName: SellerMenuKey.WITHDRAWAL_BANK_EMPTY,
  },
  [SellerMenuKey.WITHDRAWAL_BANK_BANK_ID]: {
    title: 'Edit rekening',
    routeName: SellerMenuKey.WITHDRAWAL_BANK_BANK_ID,
  },

  // -----wallet----
  [SellerMenuKey.WALLET]: {
    title: 'Dompet Mitra Bangunan',
    routeName: SellerMenuKey.WALLET,
  },

  // --------
  [SellerMenuKey.SETTINGS]: {
    title: 'Lainnya',
    backgroundCss: 'bg-gray-5',
    hideBackButton: true,
    bottomNavText: 'Lainnya',
    routeName: SellerMenuKey.SETTINGS,
    bottomNavIcon: 'icoMenu',
  },

  // ---------
  [SellerMenuKey.NOTIFICATION]: {
    title: 'Notifikasi',
    routeName: SellerMenuKey.NOTIFICATION,
  },

  [SellerMenuKey.SELL]: {
    routeName: SellerMenuKey.SELL,
  },

  [SellerMenuKey.SELLER_LOGIN_FORGOT_PASSWORD]: {
    routeName: SellerMenuKey.SELLER_LOGIN_FORGOT_PASSWORD,
    title: 'Verifikasi akun',
    backgroundCss: 'bg-white',
  },
  [SellerMenuKey.SELLER_LOGIN_RESET_PASSWORD]: {
    routeName: SellerMenuKey.SELLER_LOGIN_RESET_PASSWORD,
    title: 'Ubah password',
    backgroundCss: 'bg-white',
  },
  [SellerMenuKey.SELLER_LOGIN_VERIFY_OTP]: {
    routeName: SellerMenuKey.SELLER_LOGIN_VERIFY_OTP,
    title: 'Verifikasi akun',
    backgroundCss: 'bg-white',
  },

  [SellerMenuKey.SELLER_RESET_PASSWORD_CONFIRM_PHONE]: {
    routeName: SellerMenuKey.SELLER_RESET_PASSWORD_CONFIRM_PHONE,
    title: 'Konfirmasi Nomor Handphone',
    backgroundCss: 'bg-white',
  },

  [SellerMenuKey.SELLER_RESET_PASSWORD_NEW_PASSWORD]: {
    routeName: SellerMenuKey.SELLER_RESET_PASSWORD_NEW_PASSWORD,
    title: 'Ubah password',
    backgroundCss: 'bg-white',
  },

  [SellerMenuKey.SELLER_RESET_PASSWORD_VERIFY_OTP]: {
    routeName: SellerMenuKey.SELLER_RESET_PASSWORD_VERIFY_OTP,
    title: 'Ubah password',
    backgroundCss: 'bg-white',
  },
}
