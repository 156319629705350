import { createOpenapiAdapter } from '@bukalapak/openapi-adapter/axios'
import MitrabangunanUserDomain from '@bukalapak/openapi-schema/mitrabangunan/user/v1/public/user-domain'
import ReusablePaymentDomain from '@bukalapak/openapi-schema/reusable/payment/v1/public/payment-domain'
import type { NuxtAxiosInstance } from '@nuxtjs/axios'
import type { AxiosInstance } from 'axios'

export type Schema = MitrabangunanUserDomain & ReusablePaymentDomain

export function createOpenapi(options: {
  baseURL: string
  getToken: () => string
  instance: AxiosInstance | NuxtAxiosInstance
}) {
  return createOpenapiAdapter<Schema>({
    instance: options.instance as unknown as AxiosInstance,
    baseURL: options.baseURL,
    beforeRequest(config) {
      const token = options.getToken?.()

      if (token) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${token}`,
        }
      }

      return config
    },
  })
}
