import { MutationTree } from 'vuex'

export type SideBarVuexState = {
  activeSideBar: string[]
}

const initialState: SideBarVuexState = {
  activeSideBar: [],
}

const mutations: MutationTree<SideBarVuexState> = {
  addActiveItem(state, value: string) {
    if (!state.activeSideBar.includes(value)) {
      state.activeSideBar.push(value)
    }
  },
  removeActiveItem(state, value: string) {
    const idx = state.activeSideBar.map(item => item).indexOf(value)
    state.activeSideBar.splice(idx, 1)
  },
}

export default {
  namespaced: true,
  state: () => ({ ...initialState }),
  mutations,
  getters: {},
}
