import { balanceMutation } from '~/constants/fixtures/seller/wallet/balance-mutation'
import { APIResponse } from '~/types/api/api-response'
import { BalanceMutation } from '~/types/data/wallet/balance-mutation'

// todo: temporary solution. Will change later if API is working
export default {
  getBalanceMutation(): Promise<APIResponse<BalanceMutation[]>> {
    const result = {
      data: balanceMutation,
      meta: {
        http_status: 200,
      },
    }
    return Promise.resolve(result)
  },
}
