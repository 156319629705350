import { PublicUser } from '@bukalapak/openapigen/products/mitrabangunan/user/v1/public'
import blTracker, { Tracker } from '@bukalapak/tracker-js'
import { Plugin } from '@nuxt/types'
import jsCookie from 'js-cookie'
import UAParser from 'ua-parser-js'
import { TRACKER_DEFAULT_VALUES } from '~/constants/trackers'
import { AvailableEvent, TrackerSchema } from '~/types/tracker'
import { trackEvent } from '~/utils/tracker'

type TrackEventParams = Parameters<typeof trackEvent>

const uap = new UAParser(window.navigator.userAgent)
const os = uap.getOS()
const device = uap.getDevice()

const TrackerPlugin: Plugin = (ctx, inject) => {
  // INFO: The idea is to extract Tracker class from blTracker instance, since Tracker class is not exposed physically
  const tracker = new (blTracker.constructor as unknown as typeof Tracker)()
  const config = {
    baseUrl: ctx.$config.tracker.url || 'https://t.bukabangunan.com',
    dev: ctx.$config.tracker.dev || false,
    platform: 'desktop',
  }

  tracker.config(config)
  // Double instantiation
  blTracker.config(config)

  inject('tracker', {
    instance: tracker,
    track: <K extends TrackEventParams[0]>(event: K, payload: Partial<TrackerSchema[K]>, inst: Tracker = tracker) => {
      const reservedPayload: TrackerSchema['reserved'] = {
        app_name: 'bukabangunan',
        session_id: `${jsCookie.get('session_id') || ''}`,
        t: Date.now(),
        td_brand: device.vendor,
        td_model: device.model,
        td_os_type: os.name,
        td_os_ver: os.version,
        ui: Number((ctx.$auth.user as PublicUser)?.id ?? 0).toString(36),
      }

      const defaultPayload: TrackerSchema[K] = TRACKER_DEFAULT_VALUES[`${event as AvailableEvent}`]

      const trackerPayload = {
        ...defaultPayload,
        ...reservedPayload,
        ...payload,
      }

      return trackEvent(event, trackerPayload, inst)
    },
  })
}

export default TrackerPlugin
