import { partnersBasePath } from './partners-path'

export enum CMSAPIPath {
  articles = '/api/cms/articles',
  article = '/api/cms/articles/:id',
  categories = '/api/cms/categories',
  sitemapArticleCount = 'api/cms/sitemaps/articles/count',
}

export const CMSBasePath = {
  articles: `${partnersBasePath}/cms/articles`,
  categries: `${partnersBasePath}/cms/categories`,
  sitemap: `${partnersBasePath}/cms/sitemaps`,
}
