import { Context } from '@nuxt/types'
import { cmsClient, GetArticleParams } from '~/server/api/clients/cms'
import { APIResponse } from '~/types/api/api-response'
import { CMSAPIPath } from '~/types/api/cms'
import { Article, ArticleCategory, ArticleDetail } from '~/types/model/articles'
import { SitemapCount } from '~/types/model/sitemap'

export const CmsAPI = ({ $axios }: Context) => {
  const getArticleList = (params: Partial<GetArticleParams>) => {
    return process.server
      ? cmsClient().requestCMSArticles(params)
      : $axios.get<APIResponse<Article[]>>(CMSAPIPath.articles, { params })
  }

  const getArticleDetail = (id: number) => {
    return process.server
      ? cmsClient().requestCMSArticleDetail(id)
      : $axios.get<APIResponse<ArticleDetail>>(CMSAPIPath.article.replace(':id', `${id}`))
  }

  const getCategoryList = () => {
    return process.server
      ? cmsClient().requestCMSCategories()
      : $axios.get<APIResponse<ArticleCategory[]>>(CMSAPIPath.categories)
  }

  const getArticleSitemapCount = () => {
    return process.server
      ? cmsClient().requestCMSArticleSitemapCount()
      : $axios.get<APIResponse<SitemapCount>>(CMSAPIPath.sitemapArticleCount)
  }

  return {
    getArticleList,
    getArticleDetail,
    getCategoryList,
    getArticleSitemapCount,
  }
}
