// taken from https://github.com/nuxt-community/auth-module/blob/dev/src/schemes/oauth2.ts
import type { PublicGetUserResponse } from '@bukalapak/openapigen/products/mitrabangunan/user/v1/public'
import type { User } from '@bukalapak/protocgen/src/products/mitrabangunan/user/v1/shared/user_pb'
import type {
  EndpointsOption,
  HTTPResponse,
  // RefreshableScheme,
  RefreshableSchemeOptions,
  SchemeCheck,
  SchemeOptions,
  SchemePartialOptions,
  TokenableSchemeOptions,
  UserOptions,
} from '@nuxtjs/auth-next/dist'
import {
  Auth,
  BaseScheme,
  ExpiredAuthSessionError,
  RefreshController,
  RefreshToken,
  RequestHandler,
  Token,
} from '@nuxtjs/auth-next/dist/runtime'
import type { IncomingMessage } from 'connect'
import type * as express from 'express'
import requrl from 'requrl'
import { encodeQuery, getProp, normalizePath, parseQuery, randomString, removeTokenPrefix, urlJoin } from './utils'
import { CustomRefreshToken } from './utils/custom-refresh-token'
import { CustomToken } from './utils/custom-token'
import type { Sentry } from '~/server/services/sentry'
export interface Oauth2SchemeEndpoints extends EndpointsOption {
  authorization: string
  token: string
  userInfo: string
  logout: string | false
}

export interface Oauth2HydraSchemeOptions extends SchemeOptions, TokenableSchemeOptions, RefreshableSchemeOptions {
  endpoints: Oauth2SchemeEndpoints
  user: UserOptions
  responseMode: 'query.jwt' | 'fragment.jwt' | 'form_post.jwt' | 'jwt'
  responseType: 'code' | 'token' | 'id_token' | 'none' | string
  grantType:
    | 'implicit'
    | 'authorization_code'
    | 'client_credentials'
    | 'password'
    | 'refresh_token'
    | 'urn:ietf:params:oauth:grant-type:device_code'
  accessType: 'online' | 'offline'
  redirectUri: string
  logoutRedirectUri: string
  clientId: string | number
  clientSecret: string
  scope: string | string[]
  state: string
  codeChallengeMethod: 'implicit' | 'S256' | 'plain'
  acrValues: string
  audience: string
  autoLogout: boolean
}

const DEFAULTS: SchemePartialOptions<Oauth2HydraSchemeOptions> = {
  name: 'oauth2Hydra',
  accessType: undefined,
  redirectUri: undefined,
  logoutRedirectUri: undefined,
  clientId: undefined,
  audience: undefined,
  grantType: undefined,
  responseMode: undefined,
  acrValues: undefined,
  autoLogout: false,
  endpoints: {
    logout: undefined,
    authorization: undefined,
    token: undefined,
    userInfo: undefined,
  },
  scope: [],
  token: {
    property: 'access_token',
    type: 'Bearer',
    name: 'Authorization',
    maxAge: 1800,
    global: true,
    prefix: 'access_token',
    expirationPrefix: 'acct_expr',
  },
  refreshToken: {
    property: 'refresh_token',
    maxAge: 60 * 60 * 24 * 30,
    prefix: 'refresh_token',
    expirationPrefix: 'reft_expr',
  },
  user: {
    property: false,
  },
  responseType: 'token',
  codeChallengeMethod: 'implicit',
}

const cryptoLib = process.server ? (require('crypto').webcrypto as unknown as Crypto) : window.crypto
const TEncoder = process.server ? (require('util').TextEncoder as typeof TextEncoder) : TextEncoder
const logger = process.server
  ? (require('@bukalapak/middleware-logging').loggers.jsonLogger.error as unknown as (er: Error) => void)
  : console.error
export default class Oauth2HydraScheme<
  OptionsT extends Oauth2HydraSchemeOptions = Oauth2HydraSchemeOptions
> extends BaseScheme<OptionsT> {
  public req: IncomingMessage
  public token: Token
  public refreshToken: RefreshToken
  public refreshController: RefreshController
  public requestHandler: RequestHandler

  constructor(
    $auth: Auth,
    options: SchemePartialOptions<Oauth2HydraSchemeOptions>,
    ...defaults: SchemePartialOptions<Oauth2HydraSchemeOptions>[]
  ) {
    super($auth, options as OptionsT, ...(defaults as OptionsT[]), DEFAULTS as OptionsT)

    this.req = $auth.ctx.req

    // Initialize Token instance
    this.token = new CustomToken(this, this.$auth.$storage) as unknown as Token

    // Initialize Refresh Token instance
    this.refreshToken = new CustomRefreshToken(this, this.$auth.$storage) as unknown as RefreshToken

    // Initialize Refresh Controller
    this.refreshController = new RefreshController(this)

    // Initialize Request Handler
    this.requestHandler = new RequestHandler(this, this.$auth.ctx.$axios)

    // Tamper config at runtime
    const { domain, prefix } = this.$auth.ctx.$config.cookie
    ;(this.$auth.$storage.options.cookie as any).options.domain = domain
    ;(this.$auth.options.cookie as any).prefix = prefix
  }

  protected get scope(): string {
    return Array.isArray(this.options.scope) ? this.options.scope.join(' ') : this.options.scope
  }

  protected get redirectURI(): string {
    const basePath = this.$auth.ctx.base || ''
    const path = normalizePath(basePath + '/' + this.$auth.options.redirect.callback) // Don't pass in context since we want the base path
    return this.options.redirectUri || urlJoin(requrl(this.req), path)
  }

  protected get logoutRedirectURI(): string {
    return this.options.logoutRedirectUri || urlJoin(requrl(this.req), this.$auth.options.redirect.logout)
  }

  check(checkStatus = false): SchemeCheck {
    const response = {
      valid: false,
      tokenExpired: false,
      refreshTokenExpired: false,
      isRefreshable: true,
    }

    // Sync tokens
    const token = this.token.sync()
    this.refreshToken.sync()

    // Token is required but not available
    if (!token) {
      return response
    }

    // Check status wasn't enabled, let it pass
    if (!checkStatus) {
      response.valid = true
      return response
    }

    // Get status
    const tokenStatus = this.token.status()
    const refreshTokenStatus = this.refreshToken.status()

    // Refresh token has expired. There is no way to refresh. Force reset.
    if (refreshTokenStatus.expired()) {
      response.refreshTokenExpired = true
      return response
    }

    // Token has expired, Force reset.
    if (tokenStatus.expired()) {
      response.tokenExpired = true
      return response
    }

    response.valid = true
    return response
  }

  async mounted(): Promise<HTTPResponse | void> {
    // Forcibly end the response to prevent the request from being hanging
    // TODO Need to investigate why onError sometimes is called twice
    this.$auth.onError(error => {
      if (process.client) this.$auth.ctx.$sentry.captureException(error)
      // if (process.client) debugger
      if (!process.server) return
      const res = this.$auth.ctx.res as unknown as express.Response
      ;(res.locals.$sentry as typeof Sentry).captureException(error)
      if (!res.headersSent) {
        res.statusCode = 500
      }
    })

    // INFO: Tamper the cookie storage settings because cookie settings apparently mixed in the compilation build
    this.tamperAuth()

    const { tokenExpired, refreshTokenExpired } = this.check(true)

    // Force reset if refresh token has expired
    // Or if `autoLogout` is enabled and token has expired
    if (refreshTokenExpired || (tokenExpired && this.options.autoLogout)) {
      this.$auth.reset()
    }

    // Initialize request interceptor
    this.requestHandler.initializeRequestInterceptor(this.$auth.ctx.$config.auth.tokenUrl)

    // Handle callbacks on page load
    const redirected = await this._handleCallback()

    if (!redirected) {
      await this.$auth.fetchUserOnce()
    }
  }

  reset(): void {
    this.$auth.setUser(false)
    this.token.reset()
    this.refreshToken.reset()
    this.requestHandler.reset()
  }

  async login(_opts: { state?: string; params?; nonce?: string } = {}): Promise<void> {
    const opts = {
      protocol: 'oauth2',
      response_type: this.options.responseType,
      access_type: this.options.accessType,
      client_id: this.options.clientId,
      redirect_uri: this.redirectURI,
      scope: this.scope,
      // Note: The primary reason for using the state parameter is to mitigate CSRF attacks.
      // https://auth0.com/docs/protocols/oauth2/oauth-state
      state: _opts.state || randomString(10),
      code_challenge_method: this.options.codeChallengeMethod,
      ..._opts.params,
    }

    if (this.options.audience) {
      opts.audience = this.options.audience
    }

    // Set Nonce Value if response_type contains id_token to mitigate Replay Attacks
    // More Info: https://openid.net/specs/openid-connect-core-1_0.html#NonceNotes
    // More Info: https://tools.ietf.org/html/draft-ietf-oauth-v2-threatmodel-06#section-4.6.2
    // Keycloak uses nonce for token as well, so support that too
    // https://github.com/nuxt-community/auth-module/pull/709
    if (opts.response_type.includes('token') || opts.response_type.includes('id_token')) {
      opts.nonce = _opts.nonce || randomString(10)
    }

    if (opts.code_challenge_method) {
      switch (opts.code_challenge_method) {
        case 'plain':
        case 'S256':
          {
            const state = this.generateRandomString()
            this.$auth.$storage.setUniversal(this.name + '.pkce_state', state)
            const codeVerifier = this.generateRandomString()
            this.$auth.$storage.setUniversal(this.name + '.pkce_code_verifier', codeVerifier)
            const codeChallenge = await this.pkceChallengeFromVerifier(
              codeVerifier,
              opts.code_challenge_method === 'S256'
            )
            opts.code_challenge = encodeURIComponent(codeChallenge)
          }
          break
        case 'implicit':
        default:
          break
      }
    }

    if (this.options.responseMode) {
      opts.response_mode = this.options.responseMode
    }

    if (this.options.acrValues) {
      opts.acr_values = this.options.acrValues
    }

    this.$auth.$storage.setUniversal(this.name + '.state', opts.state)

    const url = this.$auth.ctx.$config.auth.authorizationUrl + '?' + encodeQuery(opts)

    if (process.server) {
      this.$auth.ctx.redirect(url)
    } else {
      window.location.replace(url)
    }
  }

  logout(): void {
    if (this.$auth.ctx.$config.auth.logoutUrl) {
      const opts = {
        client_id: this.options.clientId + '',
        logout_uri: this.logoutRedirectURI,
      }
      const url = this.$auth.ctx.$config.auth.logoutUrl + '?' + encodeQuery(opts)
      if (process.server) {
        this.$auth.ctx.redirect(url)
      } else {
        window.location.replace(url)
      }
    }

    // unset apollo graphql token
    this.$auth.ctx.$apolloHelpers.onLogout()

    return this.$auth.reset()
  }

  async fetchUser(): Promise<void> {
    const checker = this.check(true)
    if (!checker.valid) {
      if (process.server) {
        if (checker.tokenExpired) {
          await this.refreshTokens()
        }
      } else {
        return
      }
    }

    if (!this.$auth.ctx.$config.auth.userInfoUrl || !this.$auth.loggedIn) {
      this.$auth.setUser({})
      return
    }
    let userData: User.AsObject | PublicGetUserResponse['user'] | undefined = {}
    try {
      if (process.server) {
        // TODO: GRPC is currently not working on dev due public keys
        // userData = await this.$auth.ctx.$pb.userClient.getUser(`${this.token.get()}`.split(' ')[1], this)
        userData = {}
      } else {
        const response = await this.$auth.ctx.$api.userServiceApi.userServiceGetUser()

        userData = (response.data as PublicGetUserResponse).user
      }
    } catch (error) {
      console.warn('fetchUserError', error)
      userData = {}
    }
    this.$auth.setUser(userData)
  }

  async _handleCallback(): Promise<boolean | void> {
    // Handle callback only for specified route
    if (
      this.$auth.options.redirect &&
      normalizePath(this.$auth.ctx.route.path, this.$auth.ctx) !==
        normalizePath(this.$auth.options.redirect.callback, this.$auth.ctx)
    ) {
      return
    }

    // Callback flow only in server side
    if (!process.server) return
    const hash = parseQuery(this.$auth.ctx.route.hash.slice(1))
    const parsedQuery = { ...this.$auth.ctx.route.query, ...hash }
    // accessToken/idToken
    let token: string = parsedQuery[this.options.token.property] as string
    // refresh token
    let refreshToken: string = ''

    if (this.options.refreshToken.property) {
      refreshToken = parsedQuery[this.options.refreshToken.property] as string
    }

    // Validate state
    const state = this.$auth.$storage.getUniversal(this.name + '.state')
    this.$auth.$storage.setUniversal(this.name + '.state', null)
    if (state && parsedQuery.state !== state) {
      return
    }

    // -- Authorization Code Grant --
    if (this.options.responseType === 'code' && parsedQuery.code) {
      let codeVerifier: string | undefined
      let authHeader: Record<string, any> = this._getAuthHeader()

      // Retrieve code verifier and remove it from storage
      if (this.options.codeChallengeMethod && this.options.codeChallengeMethod !== 'implicit') {
        codeVerifier = this.$auth.$storage.getUniversal(this.name + '.pkce_code_verifier') as string
        this.$auth.$storage.setUniversal(this.name + '.pkce_code_verifier', null)
        authHeader = {}
      }

      try {
        const response = await this.$auth.request({
          method: 'post',
          url: this.$auth.ctx.$config.auth.tokenUrl,
          baseURL: '',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            ...authHeader,
          },
          data: encodeQuery({
            code: parsedQuery.code as string,
            redirect_uri: this.redirectURI,
            response_type: this.options.responseType,
            audience: this.options.audience,
            grant_type: this.options.grantType,
            ...(codeVerifier && { code_verifier: codeVerifier, client_id: this.options.clientId }),
          }),
        })

        token = (getProp(response.data, this.options.token.property) as string) || token
        refreshToken = (getProp(response.data, this.options.refreshToken.property) as string) || refreshToken

        // set apollo graphql token
        await this.$auth.ctx.$apolloHelpers.onLogin(token)
      } catch (error_) {
        // TODO: kibanna log
        logger(error_)
        this.$auth.ctx.next?.(error_)
        return false
      }
    }

    if (!token || token.length === 0) {
      return
    }

    // Set token
    if (process.server && this.$auth.ctx.res.headersSent) {
      // eslint-disable-next-line no-debugger
      debugger
    }
    this.token.set(token)

    // Store refresh token
    if (refreshToken && refreshToken.length > 0) {
      this.refreshToken.set(refreshToken)
    }
    // Redirect to home
    if (this.$auth.options.watchLoggedIn) {
      this.$auth.redirect('home', true)
      return true // True means a redirect happened
    }
  }

  async refreshTokens(): Promise<HTTPResponse | void> {
    if (process.client) {
      // TODO: useopenapigen to retrieve new token. Currently we force reset it to avoid calling internal URL from browser
      this.$auth.reset()
      return
    }

    // Get refresh token, because access_token is expired
    const refreshToken = this.refreshToken.get()

    // Access token is expired, but refresh token is not available. Force reset.
    if (!refreshToken) {
      this.$auth.reset()
      throw new ExpiredAuthSessionError()
    }

    // Get refresh token status
    const refreshTokenStatus = this.refreshToken.status()

    // Refresh token is expired. There is no way to refresh. Force reset.
    if (refreshTokenStatus.expired()) {
      this.$auth.reset()

      throw new ExpiredAuthSessionError()
    }

    // Delete current token from the request header before refreshing
    this.requestHandler.clearHeader()

    const response = await this.$auth
      .request({
        method: 'post',
        url: this.$auth.ctx.$config.auth.tokenUrl,
        baseURL: '',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          ...this._getAuthHeader(),
        },
        data: encodeQuery({
          refresh_token: removeTokenPrefix(refreshToken, this.options.token.type),
          scopes: this.scope,
          client_id: this.options.clientId + '',
          grant_type: 'refresh_token',
        }),
      })
      .catch(error => {
        this.$auth.callOnError(error, { method: 'refreshToken' })
        throw error
        // return Promise.reject(error)
      })

    this.updateTokens(response)

    return response
  }

  protected updateTokens(response: HTTPResponse): void {
    const token = getProp(response.data, this.options.token.property) as string
    const refreshToken = getProp(response.data, this.options.refreshToken.property) as string

    this.token.set(token)

    if (refreshToken) {
      this.refreshToken.set(refreshToken)
    }
  }

  protected async pkceChallengeFromVerifier(v: string, hashValue: boolean): Promise<string> {
    if (hashValue) {
      const hashed = await this._sha256(v)
      return this._base64UrlEncode(hashed)
    }
    return v // plain is plain - url-encoded by default
  }

  protected generateRandomString(): string {
    const array = new Uint32Array(28) // this is of minimum required length for servers with PKCE-enabled
    cryptoLib.getRandomValues(array)
    // eslint-disable-next-line unicorn/prefer-spread, unicorn/prefer-string-slice
    return Array.from(array, dec => ('0' + dec.toString(16)).substr(-2)).join('')
  }

  private _getAuthHeader(): { Authorization: string } {
    return {
      Authorization: `Basic ${Buffer.from(this.options.clientId + ':' + this.options.clientSecret).toString('base64')}`,
    }
  }

  private _sha256(plain: string): Promise<ArrayBuffer> {
    const encoder = new TEncoder()
    const data = encoder.encode(plain)
    return cryptoLib.subtle.digest('SHA-256', data)
  }

  private _base64UrlEncode(str: ArrayBuffer): string {
    // Convert the ArrayBuffer to string using Uint8 array to convert to what btoa accepts.
    // btoa accepts chars only within ascii 0-255 and base64 encodes them.
    // Then convert the base64 encoded to base64url encoded
    //   (replace + with -, replace / with _, trim trailing =)
    // @ts-ignore
    return Buffer.from(String.fromCharCode.apply(null, new Uint8Array(str)))
      .toString('base64')
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=+$/, '')
  }

  protected tamperAuth(): void {
    const {
      adminId,
      sellerId,
      buyerId,
      adminSecret,
      sellerSecret,
      buyerSecret,
      authorizationUrl,
      tokenUrl,
      userInfoUrl,
      logoutUrl,
      audience,
      logoutRedirUrl,
    } = this.$auth.ctx.$config.auth
    const clientPairs = {
      nvAdmin: { clientId: adminId, clientSecret: adminSecret },
      nvSeller: { clientId: sellerId, clientSecret: sellerSecret },
      nvBuyer: { clientId: buyerId, clientSecret: buyerSecret },
    } as const
    this.$auth.strategy.options = {
      ...this.$auth.getStrategy().options,
      endpoints: {
        authorization: authorizationUrl,
        token: tokenUrl,
        userInfo: userInfoUrl,
        logout: logoutUrl,
      },
      audience,
      logoutRedirectUri: logoutRedirUrl,
      clientId: clientPairs[this.$auth.strategy?.name ?? 'nvBuyer'].clientId,
      clientSecret: clientPairs[this.$auth.strategy?.name ?? 'nvBuyer'].clientSecret,
    } as unknown as SchemeOptions
  }
}
