import { TrackerSchema } from '~/types/tracker'

export const TRACKER_DEFAULT_VALUES: Omit<TrackerSchema, 'reserved'> = {
  app_action: {
    screen_name: '',
    action_name: '',
    action_type: '',
    action_source: '',
    action_parameter: '',
    action_status: '',
    target_screen_name: '',
    section_name: '',
    action_attribute: JSON.stringify('{}'),
  },

  app_screen_view: {
    screen_name: '',
    screen_url: '',
    screen_referrer: '',
    screen_parameter: '',
    screen_status: '',
    screen_attribute: JSON.stringify('{}'),
  },

  app_impression: {
    screen_name: '',
    scroll_type: '',
    section_name: '',
    item_name: '',
    duration: 0,
    impression_attribute: JSON.stringify('{}'),
  },
}
