import { MoneyFormat } from '~/types/model/money-format'

const MATCH_NUMBERS_REGEX = /^[\d-]*$/

/**
 * Convert money to number
 *
 * @example
 * moneyToNumber('1.000.000,01')
 * // => 1000000.01
 * moneyToNumber('1,000,000.01', MoneyFormat.USD)
 * // => 1000000.01
 */
export default (money: string, format: MoneyFormat = MoneyFormat.THOUSAND_DOTTED): number | undefined => {
  const numeratorUsesDotSeparator = [MoneyFormat.THOUSAND_DOTTED, MoneyFormat.IDR].includes(format)
  const currencyPrefix = format === MoneyFormat.IDR ? 'Rp' : format === MoneyFormat.USD ? '$' : ''
  const denominatorSeparator = numeratorUsesDotSeparator ? ',' : '.'
  const numeratorThousandSeparator = numeratorUsesDotSeparator ? '.' : ','
  if (!money) return
  const numeratorDenominator = money.split(denominatorSeparator)
  const numerator = (numeratorDenominator[0] ?? '0')
    .replaceAll(numeratorThousandSeparator, '')
    .replace(currencyPrefix, '')
  const denominator = (numeratorDenominator[1] ?? '').replaceAll(numeratorThousandSeparator, '')
  if (!numerator.match(MATCH_NUMBERS_REGEX) || !denominator.match(MATCH_NUMBERS_REGEX)) return undefined
  return Number.parseFloat(denominator ? `${numerator}.${denominator}` : `${numerator}`)
}
