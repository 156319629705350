import { Plugin } from '@nuxt/types'
import Vue from 'vue'
import VueGtag, { bootstrap, setOptions, setRouter } from 'vue-gtag'

declare module 'vue-gtag' {
  export function setRouter(router: any): void
}

Vue.use(VueGtag, {
  config: { id: 'G-XXXXXXXXXX' },
  bootstrap: false,
})

// based on https://github.com/MatteoGabriele/vue-gtag/blob/master/src/utils.js#L3-L30
export const loadDeferScript = (gaMeasurementId: String) => {
  const head = document.head || document.querySelectorAll('head')[0]
  const comment = document.createComment('Global Site Tag (gtag.js) - Google Analytics')

  const script1 = document.createElement('script')
  script1.async = true
  script1.src = `https://www.googletagmanager.com/gtag/js?id=${gaMeasurementId}`

  const script2 = document.createElement('script')
  script2.textContent = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', '${gaMeasurementId}');
  `
  const link = document.createElement('link')
  link.href = 'https://www.googletagmanager.com'
  link.rel = 'preconnect'

  head.appendChild(comment)
  head.appendChild(script1)
  head.appendChild(script2)
  head.appendChild(link)
}

const GA4Plugin: Plugin = async ({ app }) => {
  if (process.server) {
    return
  }

  const { gaMeasurementId } = app.$config
  setOptions({
    config: { id: `${gaMeasurementId}` },
    disableScriptLoad: true,
  })
  setRouter(app.router)
  await bootstrap()
  loadDeferScript(gaMeasurementId)
}

export default GA4Plugin
