import { Context } from '@nuxt/types'
import { ActionTree, MutationTree } from 'vuex'
import { CmsAPI } from '~/api/cms/index'
import { ArticleCategory, ArticleDetail } from '~/types/model/articles'

export type ArticleState = {
  categoryList: ArticleCategory[]
  selectedCategory: Partial<ArticleCategory>
  currentArticle: ArticleDetail | undefined
}

const initialState: ArticleState = {
  categoryList: [],
  selectedCategory: {},
  currentArticle: undefined,
}

const mutations: MutationTree<ArticleState> = {
  setCategoryList(state, payload) {
    state.categoryList = payload
  },
  setSelectedCategory(state, payload) {
    state.selectedCategory = payload
  },
  setCurrentArticle(state, newArticle: ArticleDetail) {
    state.currentArticle = newArticle
  },
}

const actions: ActionTree<ArticleState, any> = {
  async loadCategories({ commit }, context: Context) {
    try {
      const {
        data: { data },
      } = await CmsAPI(context).getCategoryList()
      commit('setCategoryList', data)
    } catch (error) {
      context.error({ statusCode: error.response?.status || 404 })
    }
  },
}

export default {
  namespaced: true,
  state: () => ({ ...initialState }),
  getters: {},
  mutations,
  actions,
}
