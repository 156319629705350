import type { MutationTree } from 'vuex'
import { SellerMenuKey } from '~/constants/seller-menus'

export type SellerMobileLayoutState = {
  pageTitle: string
  showNavBar: boolean
  customIcon: string
  showBackButton: boolean
  backgroundPage: string
  activeRoute: string
  closeablePage: boolean
}

const initialState: SellerMobileLayoutState = {
  pageTitle: '',
  showNavBar: false,
  customIcon: '',
  showBackButton: false,
  backgroundPage: 'bg-gray-5',
  activeRoute: SellerMenuKey.SELLER,
  closeablePage: false,
}

const mutations: MutationTree<SellerMobileLayoutState> = {
  updatePageTitle(state, payload: string) {
    state.pageTitle = payload
  },
  updateShowNavBar(state, payload: boolean) {
    state.showNavBar = payload
  },
  updateCustomIcon(state, payload: string) {
    state.customIcon = payload
  },
  updateShowBackButton(state, payload: boolean) {
    state.showBackButton = payload
  },
  updateBackgroundPage(state, payload: string) {
    state.backgroundPage = payload
  },
  updateActiveRoute(state, payload: string) {
    state.activeRoute = payload
  },
  updateCloseablePage(state, payload: boolean) {
    state.closeablePage = payload
  },
}

export default {
  namespaced: true,
  state: () => ({ ...initialState }),
  mutations,
  getters: {},
  actions: {},
}
