import blTracker, { Tracker } from '@bukalapak/tracker-js'
import { AvailableEvent, TrackerSchema } from '~/types/tracker'

/**
 * Generic event tracking. Use the Generics to infer the data type.
 *
 * E.g
 * ```
 * trackEvent<VisitTrack>('generic_track_visit', { ... }}) // 'payload' parameter will have VisitTrack schema
 * ```
 */
export async function trackEvent<K extends AvailableEvent>(
  event: K,
  payload: Partial<TrackerSchema['reserved']> & TrackerSchema[K],
  tracker: Tracker = blTracker
): Promise<boolean> {
  const request = {
    evn: event,
    ...payload,
  }

  if (navigator?.sendBeacon && !!tracker.$config.baseUrl) {
    const result = navigator.sendBeacon(
      `${tracker.$config.baseUrl}/e`,
      JSON.stringify({ ...request, ...tracker.$uaData })
    )
    return result
  } else {
    const result = await tracker.event(request)
    return result.ok
  }
}
