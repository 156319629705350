import { SharedMutation } from '@bukalapak/openapigen/products/reusable/payment/v1/public'
import { BalanceMutation } from '~/types/data/wallet/balance-mutation'

export const balanceMutation: BalanceMutation[] = [
  {
    id: '1',
    account_id: '1',
    action: '',
    reference_type: 'process',
    reference_id: '1',
    created_at: '2022-03-22T14:13:18.187Z',
    description: 'Pengembalian uang transaksi',
    initial_balance: '10000',
    amount: '1510000',
    final_balance: '10000',
  },
  {
    id: '2',
    account_id: '1',
    action: '',
    reference_type: 'expired',
    reference_id: '1',
    created_at: '2022-03-22T14:13:18.187Z',
    description: 'Pencairan ke rekening',
    initial_balance: '10000',
    amount: '2510000',
    final_balance: '10000',
  },
  {
    id: '3',
    account_id: '1',
    action: '',
    reference_type: 'debit',
    reference_id: '4',
    created_at: '2022-03-22T14:13:18.187Z',
    description: 'Pengembalian uang transaksi',
    initial_balance: '10000',
    amount: '-3510000',
    final_balance: '10000',
  },
  {
    id: '4',
    account_id: '1',
    action: '',
    reference_type: 'credit',
    reference_id: '1',
    created_at: '2022-03-22T14:13:18.187Z',
    description: 'Pencairan ke rekening',
    initial_balance: '10000',
    amount: '-3510000',
    final_balance: '10000',
  },
]

export const sharedBalanceMutation = {
  data: {
    meta: {
      offset_pagination: {
        limit: 5,
        offset: 0,
        total: 10,
      },
    },
    mutations: balanceMutation as SharedMutation[],
  },
}
